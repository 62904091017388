import { FC, useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { Button, Ui } from "common/components/atoms";
import { RoundDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import ExpansionPanel from "../../../../common/components/atoms/ExpansionPanel/ExpansionPanel";
import { useStoreActions } from "../../../../store/store";
import SetFundingRoundSlidePanel from "../../components/slide-panels/SetFundingRoundSlidePanel/SetFundingRoundSlidePanel";
import RoundTable from "./RoundTable/RoundTable";

type ConfirmedSubscriptionProps = {
  rounds?: RoundDTO[];
  fundraiseId: number;
};
const t = createTranslation(TranslationNS.pages, "fundraising.overview.confirmedSubscription");

const ConfirmedSubscription: FC<ConfirmedSubscriptionProps> = ({ rounds, fundraiseId }) => {
  const { companyId } = useParams();

  const { getFundraiseSummaryThunk } = useStoreActions((actions) => actions.fundraisingModel);

  const [showCreateRound, setShowCreateRound] = useState(false);

  const handleManageCreateRound = useCallback(() => setShowCreateRound((prev) => !prev), []);

  const reversedRounds = useMemo(() => (rounds ? [...rounds].reverse() : []), [rounds]);

  const onSaveRound = useCallback(() => {
    getFundraiseSummaryThunk(fundraiseId).then();
  }, [fundraiseId, getFundraiseSummaryThunk]);

  if (!reversedRounds) return null;

  return (
    <div>
      <ExpansionPanel.Toggle eventKey={"confirmedSubscription"}>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Ui.xl bold color="foregroundHigh">
              {t("title")}
            </Ui.xl>
            <Ui.xs style={{ marginTop: 4 }} color="foregroundLow">
              {t("subtitle")}
            </Ui.xs>
          </div>
          <ExpansionPanel.ToggleIgnore className="me-3">
            <Button variant="secondary" size="s" onClick={handleManageCreateRound}>
              {t("addFundingRound")}
            </Button>
          </ExpansionPanel.ToggleIgnore>
        </div>
      </ExpansionPanel.Toggle>
      <ExpansionPanel.Collapse className="pt-1 pb-1" eventKey={"confirmedSubscription"}>
        {reversedRounds.map((round, index) => (
          <RoundTable
            key={round.fundraiseRoundId}
            data={round.instruments}
            fundraiseRoundId={round.fundraiseRoundId}
            name={round.name}
            description={t("fundingRound")}
            index={index + 1}
            companyId={companyId ? +companyId : 0}
            fundraiseId={fundraiseId}
            fundingRoundTypeId={round.fundingRoundTypeId}
          />
        ))}
      </ExpansionPanel.Collapse>

      <SetFundingRoundSlidePanel
        onHide={handleManageCreateRound}
        show={showCreateRound}
        fundraiseId={fundraiseId}
        onSaved={onSaveRound}
      />
    </div>
  );
};

export default ConfirmedSubscription;
