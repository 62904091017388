import { Ui } from "common/components/atoms";
import ExpansionPanel from "common/components/atoms/ExpansionPanel/ExpansionPanel";
// import useCurrency from "common/hooks/useCurrency";
import { FundraiseTransactionBundlesDTO } from "store/modelTypes";
import { useStoreState } from "store/store";

import FundraiseCapitalIncreaseList from "./FundraiseTransactionList/FundraiseCapitalIncreaseList";

const dummyTransactions: FundraiseTransactionBundlesDTO[] = [
  {
    eventName: "Fundraising Event 1",
    transactedAt: "2023-01-01T00:00:00Z",
    numberOfBundledTransactions: 1,
    numberOfShares: 100,
    transactions: [
      {
        transactedAt: "2023-01-01T00:00:00Z",
        transactionTypeId: 1,
        typeName: "Issue",
        numberOfShares: 100,
        toName: "John Doe",
        toIsCompanyOwned: false,
        toRepresentativeName: "Jane Smith",
        toInitials: "JD",
        toAvatar: "",
        currencySymbol: "$",
        transactionTotal: 1000,
        sharePrice: 10,
        shareClassName: "Class A",
        description: "Initial issue of shares",
        statusId: 1,
        eventName: "Fundraising Event 1",
      },
      {
        transactedAt: "2023-01-01T00:00:00Z",
        transactionTypeId: 1,
        typeName: "Issue",
        numberOfShares: 1000,
        toName: "John Doe",
        toIsCompanyOwned: false,
        toRepresentativeName: "Jane Smith",
        toInitials: "JD",
        toAvatar: "",
        currencySymbol: "$",
        transactionTotal: 1000,
        sharePrice: 10,
        shareClassName: "Class A",
        description: "Initial issue of shares",
        statusId: 1,
        eventName: "Fundraising Event 1",
      },
    ],
  },
  {
    eventName: "Fundraising Event 2",
    transactedAt: "2023-02-01T00:00:00Z",
    numberOfBundledTransactions: 1,
    numberOfShares: 50,
    transactions: [
      {
        transactedAt: "2023-01-01T00:00:00Z",
        transactionTypeId: 2,
        typeName: "Sell",
        numberOfShares: 50,
        toName: "Alice Johnson",
        toIsCompanyOwned: false,
        toRepresentativeName: "Bob Brown",
        toInitials: "AJ",
        toAvatar: "",
        currencySymbol: "$",
        transactionTotal: 500,
        sharePrice: 10,
        shareClassName: "Class B",
        description: "Sale of shares",
        statusId: 2,
        eventName: "Fundraising Event 2",
      },
    ],
  },
  {
    eventName: "Fundraising Event 3",
    transactedAt: "2023-03-01T00:00:00Z",
    numberOfBundledTransactions: 1,
    numberOfShares: 200,
    transactions: [
      {
        transactedAt: "2023-01-01T00:00:00Z",
        transactionTypeId: 3,
        typeName: "Split",
        numberOfShares: 200,
        toName: "Charlie Davis",
        toIsCompanyOwned: true,
        toRepresentativeName: "Dana White",
        toInitials: "CD",
        toAvatar: "",
        currencySymbol: "$",
        transactionTotal: 2000,
        sharePrice: 10,
        shareClassName: "Class C",
        description: "Stock split",
        statusId: 3,
        eventName: "Fundraising Event 3",
      },
    ],
  },
];

const FundraiseTransaction = () => {
  // const { currencySymbol } = useCurrency();

  const fundraisePreview = useStoreState((state) => state.fundraisingModel.fundraisePreview);

  const data = fundraisePreview?.transactionBundles || dummyTransactions;
  return (
    <div>
      <ExpansionPanel.Toggle eventKey={"2"}>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Ui.xl bold color="foregroundHigh">
              Transactions
            </Ui.xl>
            <Ui.xs style={{ marginTop: 4 }} color="foregroundLow">
              Preview of the transactions resulting from this campaign
            </Ui.xs>
          </div>
        </div>
      </ExpansionPanel.Toggle>
      <ExpansionPanel.Collapse eventKey={"2"}>
        <FundraiseCapitalIncreaseList transactions={data} />
      </ExpansionPanel.Collapse>
    </div>
  );
};

export default FundraiseTransaction;
