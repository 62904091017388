import { FC, useMemo } from "react";
import { ProgressBar } from "react-bootstrap";
import classNames from "classnames";
import { differenceInDays } from "date-fns";

import { Ui } from "common/components/atoms";
import PieChart, { PieChartProps } from "common/components/atoms/Charts/Pie.chart";
import useCurrency from "common/hooks/useCurrency";
import { formatNumber } from "common/hooks/useFormatNumbers";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import classes from "./FundraisingOverview.module.scss";

const t = createTranslation(TranslationNS.pages, "fundraising.overview.summary");

type FundraisingOverviewProps = {
  raisedFunds: number;
  targetAmount: number;
  dilution: number;
  valuationPreMoney: number;
  totalInvestors: number;
  totalInvestorsCommitted: number;
  startDate: string;
  endDate: string;
  shares: number;
  sharesDiluted: number;
};

const FundraisingOverview: FC<FundraisingOverviewProps> = (props) => {
  const { currencySymbol } = useCurrency();

  const pieChartData = useMemo<PieChartProps["data"]>(() => {
    return [
      {
        id: "Raised funds",
        value: props.raisedFunds,
        color: scssVariables.positive500,
      },
      {
        id: "target",
        value: props.targetAmount,
        color: "transparent",
      },
    ];
  }, [props.raisedFunds, props.targetAmount]);

  const startDay = new Date(props.startDate);
  const endDay = new Date(props.endDate);

  const totalDays = differenceInDays(endDay, startDay);

  const daysLeft = differenceInDays(endDay, new Date()) < 0 ? 0 : differenceInDays(endDay, new Date());

  const daysPassed = totalDays - daysLeft;

  const progress = (daysPassed * 100) / totalDays;

  let progressStatus = "success";
  if (progress >= 86) {
    progressStatus = "danger";
  } else {
    if (progress > 50) {
      progressStatus = "warning";
    }
  }

  // const percentageRaised = (props.raisedFunds * 100) / props.targetAmount;
  return (
    <div className={classes.wrap}>
      <div className="d-flex pt-3 pe-3 pb-3 w-50">
        <div className={classes.chart}>
          <div className={classes.container}>
            <PieChart isInteractive={false} height={160} data={pieChartData} />
          </div>
        </div>
        <div className="d-flex flex-column ms-5">
          <div className="mt-1 mb-4">
            <Ui.xxl bold color="foregroundHigh">
              {formatNumber(props.raisedFunds)} {currencySymbol}
            </Ui.xxl>
            <Ui.s className={classes.circle}>{t("raisedFounds")}</Ui.s>
          </div>

          <div className=" mb-4">
            <Ui.xxl bold color="foregroundHigh">
              {formatNumber(props.targetAmount)} {currencySymbol}
            </Ui.xxl>
            <Ui.s className={classNames(classes.circle, classes.dashed)}>{t("targetAmount")}</Ui.s>
          </div>

          {/*<div>
            <Ui.xxl bold color="foregroundHigh">
              {formatNumber(percentageRaised, { decimals: "percent" })} %
            </Ui.xxl>
            <div style={{ padding: "5px 0 5px 0" }}>
              <ProgressBar className={classes.progress} variant={progressStatus} now={percentageRaised} />
            </div>
            <Ui.s color="foregroundMedium">Percent raised</Ui.s>
          </div>*/}
        </div>
      </div>

      <div className="ps-5 pe-5">
        <div className={classes.verticalLine}></div>
      </div>

      <div className="d-flex pt-3 pe-3 pb-3 w-50 gap-8">
        <div className="d-flex flex-column ">
          <div className="mb-4">
            <Ui.xxl className="mb-1" bold color="foregroundHigh">
              {formatNumber(props.valuationPreMoney)} {currencySymbol}
            </Ui.xxl>
            <Ui.s color="foregroundMedium">{t("valuationPreMoney")}</Ui.s>
          </div>
          <div className="mb-4">
            <Ui.xxl className="mb-1" bold color="foregroundHigh">
              {formatNumber(props.shares)}
            </Ui.xxl>
            <Ui.s color="foregroundMedium">{"Total issued share"}</Ui.s>
            <Ui.s color="foregroundMedium">{" before campaign"}</Ui.s>
          </div>
        </div>

        <div className="d-flex flex-column ">
          <div className="mb-4">
            <Ui.xxl className="mb-1" bold color="foregroundHigh">
              {t("day", { count: daysLeft })}
            </Ui.xxl>
            <div style={{ padding: "5px 0 5px 0" }}>
              <ProgressBar className={classes.progress} variant={progressStatus} now={progress} />
            </div>
          </div>
          <div className="mb-4">
            <Ui.xxl className="mb-1" bold color="foregroundHigh">
              {formatNumber(props.sharesDiluted)}
            </Ui.xxl>
            <Ui.s color="foregroundMedium">{"Total issued shares"}</Ui.s>
            <Ui.s color="foregroundMedium">{"after campaign"}</Ui.s>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FundraisingOverview;
