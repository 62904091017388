import React, { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, FormikConfig } from "formik";

import { SlidePanel } from "common/components/atoms";
import { FundraiseShareSubscriptionGetDTO } from "store/modelTypes";
import { useStoreActions, useStoreState } from "store/store";

import SetNewSubscriptionForm from "./SetNewSubscriptionForm";
import useNewSubscriptionSlidePanel, { NewSubscriptionFormValues } from "./useNewSubscriptionSlidePanel";

type FundraisingContentProps = {
  onHide: () => void;
  fundraiseRoundId: number;
  sharesSubscriptionId?: number;
  onSaved?: () => void;
};
const SetNewSubscriptionFormik: FC<FundraisingContentProps> = ({
  onHide,
  fundraiseRoundId,
  sharesSubscriptionId,
  onSaved,
}) => {
  const { companyId } = useParams();

  const [transaction, setTransaction] = useState<FundraiseShareSubscriptionGetDTO | null>(null);

  const { setFundraiseSubscriptionThunk, getFundraiseSubscriptionThunk } = useStoreActions(
    (actions) => actions.fundraisingModel
  );

  const { isGetFundraiseSubscriptionLoading } = useStoreState((state) => state.fundraisingModel);

  const { initialValues, validationSchema } = useNewSubscriptionSlidePanel(transaction);

  const handleSubmit = useCallback<FormikConfig<NewSubscriptionFormValues>["onSubmit"]>(
    async (values) => {
      try {
        if (values.countryId === 0) {
          delete values.countryId;
        }
        if (values.stakeholderId === 0) {
          delete values.stakeholderId;
        }

        await setFundraiseSubscriptionThunk({ ...values, companyId: companyId ? +companyId : 0, fundraiseRoundId });
        onSaved?.();
        onHide();
      } catch (error) {
        console.error(error);
      }
    },
    [setFundraiseSubscriptionThunk, companyId, fundraiseRoundId, onSaved, onHide]
  );

  useEffect(() => {
    if (sharesSubscriptionId) {
      getFundraiseSubscriptionThunk(sharesSubscriptionId).then((res) => {
        setTransaction(res?.data || null);
      });
    }
  }, [sharesSubscriptionId, getFundraiseSubscriptionThunk]);
  return (
    <SlidePanel.Body isLoading={isGetFundraiseSubscriptionLoading}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        <SetNewSubscriptionForm onHide={onHide} sharesSubscriptionId={sharesSubscriptionId} />
      </Formik>
    </SlidePanel.Body>
  );
};

export default SetNewSubscriptionFormik;
