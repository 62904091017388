import React, { FC, useCallback, useEffect, useState } from "react";
import { Formik } from "formik";

import { SlidePanel } from "common/components/atoms";
import { notify } from "common/utils/notify/notifyFunction";
import { FundraiseNoteConversionGetDTO } from "store/modelTypes";
import { useStoreActions } from "store/store";

import NoteConversionForm from "./NoteConversionForm";
import useNoteConversionSlidePanel, { LoanConversionFormValues } from "./useNoteConversionSlidePanel";

type Props = {
  convertibleNoteId?: number;
  onSaved?: () => void;
  onHide: () => void;
};

const NoteConversionFormik: FC<Props> = ({ convertibleNoteId, onSaved, onHide }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loanConversion, setLoanConversion] = useState<FundraiseNoteConversionGetDTO | null>(null);

  const { initialValues, validationSchema } = useNoteConversionSlidePanel(loanConversion);

  const { getFundraiseNoteConversionThunk } = useStoreActions((actions) => actions.fundraisingModel);

  const handleSubmit = useCallback(
    async (values: LoanConversionFormValues) => {
      const filesData = {
        files: values.documentFiles?.newFiles || [],
        oldFileIds: values.documentFiles?.oldFiles.map((file) => file.fileId) || [],
      };
      delete values.documentFiles;

      notify(`Round successfully ${values.loanId ? "updated" : "created"}`, true, "success");
      onSaved?.();
      onHide();
    },
    [onHide, onSaved]
  );

  useEffect(() => {
    if (convertibleNoteId) {
      setIsLoading(true);
      getFundraiseNoteConversionThunk(convertibleNoteId)
        .then((res) => {
          setLoanConversion(res.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [convertibleNoteId, getFundraiseNoteConversionThunk]);

  return (
    <SlidePanel.Body isLoading={isLoading}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        <NoteConversionForm onCancel={onHide} convertible={loanConversion} />
      </Formik>
    </SlidePanel.Body>
  );
};
export default NoteConversionFormik;
