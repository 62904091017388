import { FC, useEffect } from "react";
import { useFormikContext } from "formik";

import { useStoreActions, useStoreState } from "store/store";

import { Dropdown, Helper } from "../../atoms";

type ShareClassFieldProps = {
  className?: string;
  hideQuestion?: boolean;
  companyId: number;
};

const ShareClassField: FC<ShareClassFieldProps> = ({ className, companyId, hideQuestion }) => {
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<{ shareClassId?: number }>();

  const shareClassesCompany = useStoreState((state) => state.shareClassModel.shareClassesCompany);
  const isGetShareClassLoading = useStoreState((state) => state.shareClassModel.isGetShareClassLoading);

  const { getShareClassesCompanyThunk } = useStoreActions((actions) => actions.shareClassModel);

  useEffect(() => {
    getShareClassesCompanyThunk(companyId).then();
  }, [companyId, getShareClassesCompanyThunk]);

  return (
    <div className={className}>
      <Helper>
        <Helper.Question type="input" questionId="shareClassId" hide={hideQuestion}>
          <Dropdown
            name="shareClassId"
            label="Share class"
            selectedValue={values?.shareClassId}
            error={errors.shareClassId}
            isTouched={touched.shareClassId}
            options={shareClassesCompany}
            optionsIsObject
            onBlur={handleBlur}
            onChange={handleChange}
            isLoading={isGetShareClassLoading}
            isDisabled={isGetShareClassLoading}
          />
        </Helper.Question>
        <Helper.Answer className="mt-3" withRightMargin answerId="shareClassId" text="Please select share class" />
      </Helper>
    </div>
  );
};

export default ShareClassField;
