import { useMemo } from "react";
import { defaultTo } from "ramda";
import { number, object, string } from "yup";

import { FilesDataMultiple } from "common/components/atoms/FileUploader/FileUploader";
import {
  fields as stakeholderFields,
  getStakeholderInitialValues,
} from "common/components/molecules/StakeholderSection/Stakeholder.types";
import { FundraiseShareSubscriptionGetDTO, FundraiseShareSubscriptionPostDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

export type NewSubscriptionFormValues = Partial<FundraiseShareSubscriptionPostDTO> & {
  documentFiles?: FilesDataMultiple;
};
export const fields = {
  ...stakeholderFields,
  sharesSubscriptionId: "sharesSubscriptionId",
  fundraiseRoundId: "fundraiseRoundId",
  subscriptionStatusId: "subscriptionStatusId",
  companyId: "companyId",
  description: "description",
  subscriptionAmount: "subscriptionAmount",
  sharePrice: "sharePrice",
  shareClassId: "shareClassId",
  documentStatusId: "documentStatusId",
  documentFiles: "documentFiles",
} as const;

const tv = createTranslation(TranslationNS.validation);
const useNewSubscriptionSlidePanel = (data?: FundraiseShareSubscriptionGetDTO | null) => {
  const initialValues = useMemo<NewSubscriptionFormValues>(
    () => ({
      ...getStakeholderInitialValues(data),
      [fields.sharesSubscriptionId]: defaultTo(undefined, data?.sharesSubscriptionId),
      [fields.subscriptionStatusId]: defaultTo(1, data?.subscriptionStatusId),
      [fields.fundraiseRoundId]: defaultTo(1, data?.fundraiseRoundId),
      [fields.description]: defaultTo("", data?.description),
      [fields.companyId]: defaultTo(undefined, data?.companyId),
      [fields.subscriptionAmount]: defaultTo(undefined, data?.subscriptionAmount),
      [fields.sharePrice]: defaultTo(undefined, data?.sharePrice),
      [fields.shareClassId]: defaultTo(undefined, data?.shareClassId),
      [fields.documentStatusId]: defaultTo(undefined, data?.documentStatusId),
      [fields.documentFiles]: data?.documentFiles
        ? ({ newFiles: [], oldFiles: data?.documentFiles } as FilesDataMultiple)
        : ({ newFiles: [], oldFiles: [] } as FilesDataMultiple),
    }),
    [data]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        [fields.firstName]: string().required(tv("required")),
        [fields.lastName]: string().required(tv("required")),
        [fields.email]: string()
          .email(tv("invalidEmail"))
          .when("sendInvitationEmail", {
            is: true,
            then: string().required(tv("required")),
          }),
        [fields.companyName]: string().when(fields.isCompanyOwned, {
          is: true,
          then: string().required(tv("required")),
        }),
        [fields.subscriptionAmount]: number()
          .integer(tv("integer"))
          .required(tv("required"))
          .min(1, tv("minNumber", { number: 1 })),
        [fields.sharePrice]: number()
          .min(0.000001, tv("minNumber", { number: "0,000001" }))
          .required(tv("required")),
        [fields.shareClassId]: number().required(tv("required")),
      }),
    []
  );

  return { initialValues, validationSchema };
};

export default useNewSubscriptionSlidePanel;
