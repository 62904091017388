import { FC, useMemo } from "react";

import Button from "common/components/atoms/Button/Button";
import ContextMenu, { ContextMenuProps, MenuItemMeta } from "common/components/atoms/ContextMenu/ContextMenu";
import { MenuTabBarVerticalIcon } from "common/icons/svg";

type ActionsProps = {
  meta?: { [key: string]: string | number | boolean };
  menuItems: ContextMenuProps["items"];
  menuClickCallback?: (meta: MenuItemMeta) => void;
};
const Actions: FC<ActionsProps> = ({ meta = {}, menuClickCallback, menuItems }) => {
  const modifiedMenuItems = useMemo(() => {
    return menuItems.map((item) => {
      return {
        ...item,
        meta: {
          ...item.meta,
          ...meta,
        },
      };
    });
  }, [menuItems, meta]);

  return (
    <ContextMenu items={modifiedMenuItems} menuClickCallback={menuClickCallback}>
      <Button size="s" isOnlyIcon variant="tertiary" data-testid="plan-dropdown-btn">
        <MenuTabBarVerticalIcon color="#2B011F" />
      </Button>
    </ContextMenu>
  );
};

export default Actions;
