import { useMemo } from "react";
import { defaultTo } from "ramda";
import { number, object } from "yup";

import { FilesDataMultiple, UploadedFile } from "common/components/atoms/FileUploader/FileUploader";
import { FundraiseLoanConversionGetDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import { fields as fieldsConversion } from "../fields/fieldsConversion";

type LoanConversionPostDTO = {
  loanId: number;
  sharesAllocation: number;
  sharePrice: number;
  shareClassId: number;
  comment: string;
  filesData?: { files: File[]; oldFileIds: number[] };
  documentStatusId: number;
};

type LoanConversionGetDTO = {
  loanId: number;
  sharesAllocation: number;
  sharePrice: number;
  shareClassId: number;
  comment: string;
  documentFiles: UploadedFile[];
  documentStatusId: number;
};

export type LoanConversionFormValues = Partial<LoanConversionPostDTO> & { documentFiles?: FilesDataMultiple };

export const fields = {
  convertibleLoanId: "convertibleLoanId",
  ...fieldsConversion,
} as const;

const tv = createTranslation(TranslationNS.validation);
const useLoanConversionSlidePanel = (data?: FundraiseLoanConversionGetDTO | null) => {
  const initialValues = useMemo<LoanConversionFormValues>(
    () => ({
      [fields.convertibleLoanId]: defaultTo(undefined, data?.convertibleLoanId),
      [fields.sharesAllocation]: defaultTo(undefined, data?.sharesAllocation),
      [fields.sharePrice]: defaultTo(undefined, data?.sharePrice),
      [fields.shareClassId]: defaultTo(0, data?.shareClassId),
      [fields.comment]: defaultTo("", data?.comment),
      [fields.documentFiles]: data?.documentFiles
        ? { newFiles: [], oldFiles: data?.documentFiles }
        : { newFiles: [], oldFiles: [] },
    }),
    [data]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        [fields.sharesAllocation]: number()
          .required(tv("required"))
          .min(1, tv("minNumber", { number: 1 }))
          .integer(tv("integer")),
      }),
    []
  );

  return { initialValues, validationSchema };
};

export default useLoanConversionSlidePanel;
