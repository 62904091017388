import { FC } from "react";

import { NewAvatar, Tooltip, Ui } from "common/components/atoms";
import { UserIcon } from "common/icons/svg";

import classes from "./NameCell.module.scss";

type NameCellProps = {
  representByName?: string;
  name: string;
  isAttached: boolean;
  initials?: string;
  isCompany?: boolean;
};

const NameCell: FC<NameCellProps> = ({ name, isAttached, initials, isCompany, representByName }) => {
  return (
    <div className="d-flex align-items-center gap-1">
      <NewAvatar initials={initials || "-"} size="m" company={isCompany} />
      <div>
        <Ui.s bold>{name}</Ui.s>
        {isCompany && <Ui.xs color="foregroundLow">{representByName}</Ui.xs>}
      </div>
      {isAttached && (
        <Tooltip popupContent={<div>Already a stakeholder in the company.</div>}>
          <div className={classes.icon}>
            <UserIcon />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default NameCell;
