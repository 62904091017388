import { useState } from "react";
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";

import useCurrency from "common/hooks/useCurrency";
import { FundraiseCapTableGetDTO } from "store/modelTypes";

import CapTableCard from "../../../components/CapTableCard/CapTableCard";
import DashboardTable from "../../../components/DashboardTable/DashboardTable";
import useCapTableActual from "./useCapTableActual";

const dummyData: FundraiseCapTableGetDTO[] = [
  {
    stakeholderId: 1,
    isCompanyOwned: false,
    ownershipPercentage: 2,
    relationshipType: "Employee",
    shareClassName: "Class A",
    shares: 10000,
    stakeholderInitials: "AB",
    stakeholderName: "Alice B",
    stakeholderRepresentedBy: "Alice B",
    totalValue: 50505,
    isImportant: true,
  },
  {
    isCompanyOwned: true,
    ownershipPercentage: 3,
    relationshipType: "Employee",
    shareClassName: "Class A",
    shares: 11000,
    stakeholderInitials: "CD",
    stakeholderName: "Charlie D",
    stakeholderRepresentedBy: "Charlie D",
    totalValue: 60606,
    isImportant: false,
    stakeholderId: 2,
  },
];

const CapTableActual = () => {
  const [sorting, setSorting] = useState<SortingState>([{ id: "stakeholderInitials", desc: false }]);
  const { currencySymbol } = useCurrency();

  const { columns } = useCapTableActual(currencySymbol);

  const table = useReactTable({
    data: dummyData,
    columns,
    state: {
      sorting,
    },
    meta: {
      footerShow: true,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <div className="d-flex gap-3 mb-4">
        <CapTableCard label={"Total shareholders"} value={"13"} />
        <CapTableCard label={"Total shares"} value={"56 750"} />
      </div>
      <div>
        <DashboardTable table={table} type="capTable" />
      </div>
    </div>
  );
};

export default CapTableActual;
