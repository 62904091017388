import { FC, useCallback, useMemo, useState } from "react";

import { Button, ContextMenu, ContextMenuProps, Ui } from "common/components/atoms";
import { MenuItem } from "common/components/atoms/ContextMenu/ContextMenu";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import {
  ArrowDownSmallIcon,
  ArrowUpSmallIcon,
  DeleteIcon,
  EditIcon,
  FileTextSearchIcon,
  MenuTabBarVerticalIcon,
  PlusIcon,
} from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import { useStoreActions } from "../../../../../store/store";
import DeleteRoundModal from "../../../components/modals/DeleteRoundModal/DeleteRoundModal";
import AddInstrumentsSlidePanel from "../../../components/slide-panels/AddInstrumentsSlidePanel/AddInstrumentsSlidePanel";
import SetFundingRoundSlidePanel from "../../../components/slide-panels/SetFundingRoundSlidePanel/SetFundingRoundSlidePanel";
import SetNewSubscriptionSidePanel from "../../../components/slide-panels/SetNewSubscriptionSidePanel/SetNewSubscriptionSlidePanel";
import classes from "./Round.module.scss";

type RoundHeaderProps = {
  index: number;
  name: string;
  description: string;
  totalSubscriptionAmount: number;
  totalShares: number;
  totalSharesFullyDiluted: number;
  companyId: number;
  fundraiseRoundId: number;
  fundraiseId: number;
  fundingRoundTypeId: number;
};

const t = createTranslation(TranslationNS.pages, "fundraising.overview.confirmedSubscription.round");

const RoundHeader: FC<RoundHeaderProps> = ({
  index,
  name,
  description,
  totalSubscriptionAmount,
  totalShares,
  totalSharesFullyDiluted,
  companyId,
  fundraiseRoundId,
  fundraiseId,
  fundingRoundTypeId,
}) => {
  const fNumber = useFormatNumbers();
  const { getFundraiseSummaryThunk } = useStoreActions((actions) => actions.fundraisingModel);

  const [showAddInstruments, setShowAddInstruments] = useState(false);
  const [showUpdateRound, setShowUpdateRound] = useState(false);
  const [showDeleteRoundModal, setShowDeleteRoundModal] = useState(false);
  const [showCreateShareIssue, setShowCreateShareIssue] = useState(false);

  const handleManageDeleteRound = useCallback(() => {
    setShowDeleteRoundModal((prev) => !prev);
  }, []);

  const manageAddInstruments = useCallback(() => {
    setShowAddInstruments((prev) => !prev);
  }, []);

  const handleManageUpdateRound = useCallback(() => {
    setShowUpdateRound((prev) => !prev);
  }, []);

  const handleManageCreateShareIssue = useCallback(() => {
    setShowCreateShareIssue((prev) => !prev);
  }, []);

  const handleRoundSaved = useCallback(() => {
    getFundraiseSummaryThunk(fundraiseId).then();
  }, [fundraiseId, getFundraiseSummaryThunk]);

  const menuItems = useMemo<ContextMenuProps["items"]>(() => {
    const items: MenuItem[] = [
      {
        key: "view",
        icon: <FileTextSearchIcon />,
        label: "View Details",
        onClick: () => {},
      },
      {
        key: "edit",
        icon: <EditIcon />,
        label: t("edit"),
        onClick: handleManageUpdateRound,
      },
    ];

    if (fundingRoundTypeId !== 1) {
      items.push({
        key: "moveUp",
        icon: <ArrowUpSmallIcon />,
        label: "Move up",
        onClick: () => {},
      });

      items.push({
        key: "moveDown",
        icon: <ArrowDownSmallIcon />,
        label: "Move down",
        onClick: () => {},
      });

      items.push({
        key: "delete",
        icon: <DeleteIcon />,
        label: t("delete"),
        onClick: handleManageDeleteRound,
        type: "delete",
      });
    }

    return items;
  }, [fundingRoundTypeId, handleManageDeleteRound, handleManageUpdateRound]);

  return (
    <div className={classes.header}>
      <div className="d-flex align-items-center">
        <div className={classes.step}>
          <Ui.m bold>{index}</Ui.m>
        </div>
        <div>
          <Ui.l bold color="foregroundMedium">
            {name}
          </Ui.l>
          <Ui.xs className={classes.description} color="foregroundMedium">
            {description}
          </Ui.xs>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className={classes.item}>
          <Ui.m bold color="foregroundLow">
            {fNumber(totalSubscriptionAmount)}
          </Ui.m>
          <Ui.xs color="foregroundLow">{t("totalAmount")}</Ui.xs>
        </div>
        <div className={classes.item}>
          <Ui.m bold color="foregroundLow">
            {fNumber(totalShares, "amount")}
          </Ui.m>
          <Ui.xs color="foregroundLow">{t("shares")}</Ui.xs>
        </div>
        <div className={classes.item}>
          <Ui.m bold color="foregroundLow">
            {fNumber(totalSharesFullyDiluted, "amount")}
          </Ui.m>
          <Ui.xs color="foregroundLow">{t("sharesFullyDiluted")}</Ui.xs>
        </div>

        <div className={classes.item}>
          <Button
            variant="secondary"
            isOnlyIcon
            size="s"
            onClick={fundingRoundTypeId === 1 ? handleManageCreateShareIssue : manageAddInstruments}
          >
            <PlusIcon />
          </Button>
          <ContextMenu items={menuItems}>
            <Button className="ms-2" variant="secondary" isOnlyIcon size="s">
              <MenuTabBarVerticalIcon />
            </Button>
          </ContextMenu>
        </div>
      </div>

      <AddInstrumentsSlidePanel
        companyId={companyId}
        show={showAddInstruments}
        name={name}
        onHide={manageAddInstruments}
        fundraiseRoundId={fundraiseRoundId}
        fundingRoundTypeId={fundingRoundTypeId}
      />

      <DeleteRoundModal
        fundraiseRoundId={fundraiseRoundId}
        fundraiseId={fundraiseId}
        roundName={name}
        onHide={handleManageDeleteRound}
        show={showDeleteRoundModal}
      />

      <SetFundingRoundSlidePanel
        show={showUpdateRound}
        onHide={handleManageUpdateRound}
        fundraiseId={fundraiseId}
        fundraiseRoundId={fundraiseRoundId}
        onSaved={handleRoundSaved}
      />

      <SetNewSubscriptionSidePanel
        show={showCreateShareIssue}
        onHide={handleManageCreateShareIssue}
        fundraiseRoundId={fundraiseRoundId}
        onSaved={handleRoundSaved}
      />
    </div>
  );
};

export default RoundHeader;
