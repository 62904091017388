import { FC } from "react";
import { Form, useFormikContext } from "formik";

import { Button, Dropdown, SlidePanel, TextArea, TextField } from "common/components/atoms";
import { DocumentStatusField, UploadDocumentsField } from "common/components/molecules/formikFields";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { useStoreState } from "store/store";

import { fields, FundingRoundFormValues } from "./useFundingRoundSlidePanel";

type SetFundingRoundFormProps = {
  onCancel: () => void;
};
const SetFundingRoundForm: FC<SetFundingRoundFormProps> = ({ onCancel }) => {
  const fundingRoundTypes = useStoreState((state) => state.common?.dropdowns?.fundingRoundTypes || []);

  const { values, handleChange, errors, touched, isSubmitting, handleBlur } =
    useFormikContext<FundingRoundFormValues>();

  return (
    <Form>
      <ToastFormikValidator />
      <SlidePanel.Section title={"Funding round details"}>
        <TextField
          label="Funding round name"
          placeholder="E.g. Loan conversion, Convert warrants"
          name={fields.name}
          value={values.name}
          isTouched={touched.name}
          error={errors.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <Dropdown
          optionsIsObject
          className="mt-4"
          label="Funding round type"
          placeholder="Select type"
          name={fields.fundraiseRoundTypeId}
          selectedValue={values.fundraiseRoundTypeId}
          isTouched={touched.fundraiseRoundTypeId}
          error={errors.fundraiseRoundTypeId}
          onChange={handleChange}
          onBlur={handleBlur}
          options={fundingRoundTypes}
          isDisabled={!!values.fundingRoundId}
        />

        <TextArea
          className="mt-4"
          label="Funding round description"
          placeholder="Enter a description to help separate this funding round from others"
          name={fields.description}
          value={values.description}
          isTouched={touched.description}
          error={errors.description}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </SlidePanel.Section>
      <SlidePanel.Section title={"Documentation"}>
        <UploadDocumentsField />
        <DocumentStatusField />
      </SlidePanel.Section>
      <SlidePanel.Actions>
        <Button type="submit" isLoading={isSubmitting} isDisabled={isSubmitting}>
          {values.fundingRoundId ? "Update" : "Add"}
        </Button>
        <Button className="ms-4" variant="secondary" isDisabled={isSubmitting} onClick={onCancel}>
          Cancel
        </Button>
      </SlidePanel.Actions>
    </Form>
  );
};

export default SetFundingRoundForm;
