import React, { FC, useCallback, useState } from "react";

import { SlidePanel } from "common/components/atoms";

import SetFundingRoundFormik from "./SetFundingRoundFormik";

type SetShareIssueSlidePanelProps = {
  show: boolean;
  onHide: () => void;
  onSaved?: () => void;
  fundraiseId?: number;
  fundraiseRoundId?: number;
};

const SetFundingRoundSlidePanel: FC<SetShareIssueSlidePanelProps> = ({
  show,
  onHide,
  fundraiseId,
  onSaved,
  fundraiseRoundId,
}) => {
  const [isSaved, setSiSaved] = useState(false);

  const handleSave = useCallback(() => {
    setSiSaved(true);
  }, []);

  const handleOnExited = useCallback(() => {
    if (isSaved) {
      onSaved?.();
      setSiSaved(false);
    }
  }, [onSaved, isSaved]);

  return (
    <SlidePanel show={show} onExited={handleOnExited}>
      <SlidePanel.Header title={fundraiseRoundId ? "Add funding round" : "Edit funding round"} onHide={onHide} />
      <SetFundingRoundFormik
        fundraiseId={fundraiseId}
        onHide={onHide}
        onSaved={handleSave}
        fundraiseRoundId={fundraiseRoundId}
      />
    </SlidePanel>
  );
};
export default SetFundingRoundSlidePanel;
