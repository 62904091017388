import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import axios from "axios";
import cn from "classnames";
import { defaultTo } from "ramda";

import { Button, H, LinkHelpText, LoaderContainer, NewAvatar, Tooltip, Ui } from "common/components/atoms";
import {
  ActiveUserBudgeIcon,
  CalendarIcon,
  CloseIcon,
  CompanyBuildingIcon,
  CopyNewIcon,
  EmailIcon,
  MailboxIcon,
  PhoneIcon,
  PinLocationIcon,
} from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { notify } from "common/utils/notify/notifyFunction";
import { StakeholderInvitationStatus } from "pages/equity-management/ownership/stakeholders-management/types";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { CapTableStakeholderDetailsType } from "../../../../equity-management/ownership/cap-table/CapTable.types";
import classes from "./ShareholderDetailsModal.module.scss";

const [t] = [createTranslation(TranslationNS.pages, "company.capTable.shareholderDetails")];

type PropsTypes = {
  stakeholderId: number;
  handleClose: () => void;
  api?: string;
  children?: ReactNode;
};

const API = "/api/equity-management/cap-table/stakeholder-details/{stakeholderId}";

const StakeholderContentModal: FC<PropsTypes> = ({ stakeholderId, api, children, handleClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const relationships = useStoreState((state) => state.common?.dropdowns)?.relationshipTypes;

  const [stakeholderDetails, setStakeholderDetails] = useState<CapTableStakeholderDetailsType | null>(null);

  const isCompany = Boolean(stakeholderDetails?.isCompanyOwned);

  const currentRelationship = relationships?.find((el) => el.id === stakeholderDetails?.relationshipTypeId);

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const request = await axios.get<CapTableStakeholderDetailsType>(
        api || API.replace("{stakeholderId}", stakeholderId.toString())
      );
      setStakeholderDetails(request.data);
    } catch (e) {
      console.warn({ e });
    } finally {
      setIsLoading(false);
    }
  }, [stakeholderId, api]);

  useEffect(() => {
    if (stakeholderId) {
      getData().then();
    }
  }, [getData, stakeholderId]);

  return (
    <div>
      <Button variant="dark" size="s" className={classes["close-btn"]} isOnlyIcon onClick={handleClose}>
        <CloseIcon fontSize={24} />
      </Button>
      <div className={cn(classes["top-container"], "px-4")}>
        <LoaderContainer variant="contentScreen" loading={isLoading}>
          <div className={classes.section}>
            <NewAvatar
              company={isCompany}
              imageUrl={stakeholderDetails?.avatarFilePath}
              initials={stakeholderDetails?.initials}
              className={classes.image}
            />

            <div className="d-flex align-items-center mt-2 mb-1">
              <H.s>
                {isCompany
                  ? stakeholderDetails?.companyName
                  : `${stakeholderDetails?.firstName} ${stakeholderDetails?.lastName} `}
              </H.s>
              {stakeholderDetails?.invitationStatus === StakeholderInvitationStatus.active && (
                <Tooltip popupContent={<div>{t("invitation.registeredUser")}</div>}>
                  <ActiveUserBudgeIcon fontSize={20} className="ms-1" />
                </Tooltip>
              )}
            </div>

            <Ui.m className="mb-2" style={{ color: scssVariables.foregroundLow }}>
              {currentRelationship?.name}
            </Ui.m>
          </div>

          {isCompany ? (
            <div
              className={cn("ps-5", classes.section, {
                [classes.bordered]: true,
              })}
            >
              <div className="d-flex align-items-center mb-3">
                <EmailIcon fontSize={24} className="me-4" color={scssVariables.foregroundLow} />
                {stakeholderDetails?.email ? (
                  <a href={`mailto:${stakeholderDetails.email}`} className="d-flex align-items-center">
                    <Ui.s underline color="primary1" className="me-half">
                      {stakeholderDetails.email}
                    </Ui.s>

                    <LinkHelpText
                      title=""
                      overlayClass={classes["email-overlay"]}
                      content={t("copyAddress")}
                      onClick={() => {
                        navigator.clipboard.writeText(defaultTo("", stakeholderDetails.email)).then(() => {
                          notify(t("emailCopied"), true, "success", 1500);
                        });
                      }}
                    >
                      <CopyNewIcon color={scssVariables.primary1} strokeWidth={0.6} />
                    </LinkHelpText>
                  </a>
                ) : (
                  <div style={{ color: scssVariables.foregroundLow }}>-</div>
                )}
              </div>

              <div className="d-flex align-items-center mb-3">
                <CompanyBuildingIcon fontSize={24} className="me-4" color={scssVariables.foregroundLow} />

                {stakeholderDetails?.organizationNumber ? (
                  <Ui.m>{stakeholderDetails.organizationNumber}</Ui.m>
                ) : (
                  <div style={{ color: scssVariables.foregroundLow }}>-</div>
                )}
              </div>

              <div className="d-flex align-items-center pe-2 mb-3">
                <PinLocationIcon fontSize={24} color={scssVariables.foregroundLow} className="me-4" />

                {stakeholderDetails?.address ? (
                  <Ui.m>{stakeholderDetails.address}</Ui.m>
                ) : (
                  <div style={{ color: scssVariables.foregroundLow }}>-</div>
                )}
              </div>

              <div className="d-flex align-items-center pe-2 mb-3">
                <MailboxIcon fontSize={24} color={scssVariables.foregroundLow} className="me-4" />

                {stakeholderDetails?.businessPostAddress ? (
                  <Ui.m>{stakeholderDetails.businessPostAddress}</Ui.m>
                ) : (
                  <div style={{ color: scssVariables.foregroundLow }}>-</div>
                )}
              </div>

              <div className="d-flex align-items-center pe-2 mb-3">
                <NewAvatar
                  company={false}
                  imageUrl={stakeholderDetails?.avatarFilePath}
                  initials={`${stakeholderDetails?.firstName && stakeholderDetails?.firstName[0]}${
                    stakeholderDetails?.lastName && stakeholderDetails.lastName[0]
                  }`}
                  className={cn(classes.avatar, "me-4")}
                />

                <Ui.m>{`${stakeholderDetails?.firstName} ${stakeholderDetails?.lastName}`}</Ui.m>
              </div>

              <div className="d-flex align-items-center">
                <PhoneIcon fontSize={24} className="me-4" color={scssVariables.foregroundLow} />

                {stakeholderDetails?.phoneNumber ? (
                  <Ui.m>{stakeholderDetails.phoneNumber}</Ui.m>
                ) : (
                  <div style={{ color: scssVariables.foregroundLow }}>-</div>
                )}
              </div>
            </div>
          ) : (
            <div className={cn("ps-5", classes.section, classes.bordered)}>
              <div className="d-flex align-items-center mb-3">
                <EmailIcon fontSize={24} className="me-4" color={scssVariables.foregroundLow} />

                {stakeholderDetails?.email ? (
                  <a href={`mailto:${stakeholderDetails.email}`} className="d-flex align-items-center">
                    <Ui.s underline color="primary1" className="me-half">
                      {stakeholderDetails.email}
                    </Ui.s>

                    <LinkHelpText
                      title=""
                      content={t("copyAddress")}
                      overlayClass={classes["email-overlay"]}
                      onClick={() => {
                        navigator.clipboard.writeText(defaultTo("", stakeholderDetails.email)).then(() => {
                          notify(t("emailCopied"), true, "success", 1500);
                        });
                      }}
                    >
                      <CopyNewIcon color={scssVariables.primary1} strokeWidth={0.6} />
                    </LinkHelpText>
                  </a>
                ) : (
                  <div style={{ color: scssVariables.foregroundLow }}>-</div>
                )}
              </div>

              <div className="d-flex align-items-center mb-3">
                <PhoneIcon fontSize={24} className="me-4" color={scssVariables.foregroundLow} />

                {stakeholderDetails?.phoneNumber ? (
                  <Ui.m>{stakeholderDetails.phoneNumber}</Ui.m>
                ) : (
                  <div style={{ color: scssVariables.foregroundLow }}>-</div>
                )}
              </div>

              <div className="d-flex align-items-center mb-3">
                <CalendarIcon fontSize={24} color={scssVariables.foregroundLow} className="me-4" />

                {stakeholderDetails?.dateOfBirth ? (
                  <Ui.m>{transformDateToCommonDateFormat(stakeholderDetails.dateOfBirth)}</Ui.m>
                ) : (
                  <div style={{ color: scssVariables.foregroundLow }}>-</div>
                )}
              </div>

              <div className="d-flex align-items-center pe-2">
                <PinLocationIcon fontSize={24} color={scssVariables.foregroundLow} className="me-4" />
                {stakeholderDetails?.address ? (
                  <Ui.m>{stakeholderDetails?.address}</Ui.m>
                ) : (
                  <div style={{ color: scssVariables.foregroundLow }}>-</div>
                )}
              </div>
            </div>
          )}
        </LoaderContainer>
      </div>
      {children}
    </div>
  );
};

export default StakeholderContentModal;
