import { Image } from "react-bootstrap";

import { P, Ui } from "common/components/atoms";

import Img1 from "./kickstart.png";
const Kickstart = () => {
  return (
    <div className="p-5" style={{ background: "white" }}>
      <div className="d-flex gap-3 align-items-center">
        <div>
          <Ui.xl className="mb-3" bold>
            Kickstart your fundraising effort with confidence
          </Ui.xl>
          <P.m className="mb-3">
            This section aims to make your fundraising process smooth and efficient, helping you focus on what matters
            most – reaching your goals and growing your business 🚀
          </P.m>
          <P.m className="mb-3">Get started by exploring our fundraising guide below.</P.m>
          <P.m>
            When you are ready to get started click the “Start new fundraise” button. You’ll be able to revisit this
            guide any time you want.
          </P.m>
        </div>
        <div>
          <Image src={Img1} />
        </div>
      </div>
    </div>
  );
};

export default Kickstart;
