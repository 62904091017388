import React, { FC, useCallback, useState } from "react";

import { SlidePanel } from "common/components/atoms";

import { createTranslation, TranslationNS } from "../../../../../../translation";
import LoanConversionFormik from "./LoanConversionFormik";

type LoanConversionSlidePanelProps = {
  show: boolean;
  onHide: () => void;
  onSaved?: () => void;
  convertibleLoanId?: number;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.conversions.loanPanel");

const LoanConversionSlidePanel: FC<LoanConversionSlidePanelProps> = ({ show, onHide, convertibleLoanId, onSaved }) => {
  const [isSaved, setSiSaved] = useState(false);

  const handleSave = useCallback(() => {
    setSiSaved(true);
  }, []);

  const handleOnExited = useCallback(() => {
    if (isSaved) {
      onSaved?.();
      setSiSaved(false);
    }
  }, [onSaved, isSaved]);

  return (
    <SlidePanel show={show} onExited={handleOnExited}>
      <SlidePanel.Header title={t("title")} onHide={onHide} />
      <LoanConversionFormik onHide={onHide} onSaved={handleSave} convertibleLoanId={convertibleLoanId} />
    </SlidePanel>
  );
};
export default LoanConversionSlidePanel;
