import { FC } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";

import { Helper, TextField } from "common/components/atoms";
import useCurrency from "common/hooks/useCurrency";
import { formatNumber } from "common/hooks/useFormatNumbers";
import { createTranslation, TranslationNS } from "translation";

import { fields, FundraisingFormType } from "../useFundraisingSlidePanel";

type AmountToRiseFieldProps = {
  className?: string;
  currentValuation: number;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.fundraising.fields");

const CompanyValuationField: FC<AmountToRiseFieldProps> = ({ className, currentValuation }) => {
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<FundraisingFormType>();

  const { currencySymbol } = useCurrency();

  const info = t("currentValuation", { currentValuation: formatNumber(currentValuation) });

  return (
    <Row className={className}>
      <Col>
        <Helper>
          <Helper.Question type="input" questionId={fields.preMoneyValuation}>
            <TextField
              name={fields.preMoneyValuation}
              label={t("companyValuation")}
              value={values.preMoneyValuation}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.preMoneyValuation}
              isTouched={touched.preMoneyValuation}
              iconRight={currencySymbol}
              info={info}
              type="number"
            />
          </Helper.Question>
          <Helper.Answer className="mt-3" withRightMargin answerId={fields.preMoneyValuation} text={"Answer"} />
        </Helper>
      </Col>
    </Row>
  );
};
export default CompanyValuationField;
