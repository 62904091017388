import { FC, ReactNode } from "react";
import FormLabel from "react-bootstrap/FormLabel";
import cn from "classnames";

import { Ui } from "common/components/atoms/Typography";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

export type InputHeaderProps = {
  label?: string;
  isOptional?: boolean;
  isDisabled?: boolean;
  meta?: string | ReactNode;
  className?: string;
};

const t = createTranslation(TranslationNS.common, "components.inputLabel");

const InputHeader: FC<InputHeaderProps> = ({ label, isOptional = false, isDisabled = false, meta, className }) => {
  if (!label) {
    return null;
  }

  return (
    <FormLabel
      className={cn("d-flex justify-content-between mb-half", {
        "text-disabled": isDisabled,
        [className as string]: className,
      })}
    >
      <div className="d-flex align-items-end">
        <Ui.m className="me-1 font-weight-normal">{label}</Ui.m>
        {isDisabled && <Ui.s italic>{t("disabled")}</Ui.s>}
        {!isDisabled && isOptional && (
          <Ui.s italic style={{ color: scssVariables.foregroundLow }}>
            {t("optional")}
          </Ui.s>
        )}
      </div>

      {typeof meta === "string" ? (
        <Ui.s
          italic
          className="d-flex align-items-end"
          style={{ color: isDisabled ? scssVariables.foregroundDisabled : scssVariables.foregroundLow }}
        >
          {meta}
        </Ui.s>
      ) : (
        meta
      )}
    </FormLabel>
  );
};

export default InputHeader;
