import { FC, useEffect } from "react";
import { useFormikContext } from "formik";

import { TextField } from "common/components/atoms";
import useCurrency from "common/hooks/useCurrency";
import { createTranslation, TranslationNS } from "translation";

import { ConversionFormValues, fields } from "./fieldsConversion";

type Props = {
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.conversions.fields");

const InterestAmountField: FC<Props> = ({ className }) => {
  const { values, handleChange, handleBlur, errors, touched, setFieldValue } = useFormikContext<ConversionFormValues>();
  const { currencySymbol } = useCurrency();

  useEffect(() => {
    if (values.interestPaidInCash) {
      setFieldValue(fields.interestAmount, 0);
    }
  }, [setFieldValue, values.interestPaidInCash]);

  return (
    <div className={className}>
      <TextField
        name={fields.interestAmount}
        label={t("interestAmount.label")}
        value={values.interestAmount}
        isDisabled={Boolean(values.interestPaidInCash)}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.interestAmount}
        isTouched={touched.interestAmount}
        type="number"
        iconRight={currencySymbol}
      />
    </div>
  );
};
export default InterestAmountField;
