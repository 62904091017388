import { UploadedFile } from "common/components/atoms/FileUploader/FileUploader";

export const fields = {
  sharesAllocation: "sharesAllocation",
  sharePrice: "sharePrice",
  shareClassId: "shareClassId",
  interestAmount: "interestAmount",
  comment: "comment",
  filesData: "filesData",
  documentFiles: "documentFiles",
  documentStatusId: "documentStatusId",
  conversionDate: "conversionDate",
  loanRepaymentAmount: "loanRepaymentAmount",
  interestPaidInCash: "interestPaidInCash",
} as const;

export type ConversionFormValues = {
  sharesAllocation?: number;
  sharePrice?: number;
  shareClassId?: number;
  comment?: string;
  documentFiles?: UploadedFile[];
  documentStatusId?: number;
  conversionDate?: string;
  interestAmount?: number;
  loanRepaymentAmount?: number;
  interestPaidInCash?: boolean;
};
