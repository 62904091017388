import { useMemo } from "react";

import { getEMPath, getFundraisingPath } from "app/Router/RouterHelper";
import {
  AccessIcon,
  AgreementIcon,
  BankIcon,
  DocumentsIcon,
  EquityIcon,
  ExercisingIcon,
  FundraiseIcon,
  InformationCircleOutlinedIcon,
  PieChartIcon,
  PoolsIcon,
  ShareClassesIcon,
  TaxIcon,
  TransactionsIcon,
  UsersIcon,
} from "common/icons/svg";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import useGettingStartedGuide from "../../pages/equity-management/getting-started-guide/useGettingStartedGuide";

const t = createTranslation(TranslationNS.pages, "company.sideBar");

const useCompanyMenu = (companyId: string | number) => {
  const companies = useStoreState((state) => state.account?.user?.companies);

  const notCompletedTooltip = useMemo(() => {
    const notCompletedOnboarding = (companies || []).find((el) => el.id === Number(companyId))?.notCompletedOnboarding;
    if (notCompletedOnboarding) {
      return <div style={{ width: 148, textAlign: "left" }}>Finish company registration to access this page.</div>;
    }
    return undefined;
  }, [companies, companyId]);

  const { progress, PieChartProgress } = useGettingStartedGuide();

  const companyMenus = useMemo(() => {
    const additionalSettingsMenuMenu = [];
    if (progress !== 100) {
      additionalSettingsMenuMenu.push({
        to: getEMPath(["gettingStartedGuide"], { companyId }),
        icon: <PieChartProgress />,
        label: t("settings.routes.quickstartGuide", { progress }),
      });
    }
    return additionalSettingsMenuMenu;
  }, [companyId, progress, PieChartProgress]);

  const equityManagement = useMemo(() => {
    return [
      {
        to: getEMPath(["ownership", "capTable"], { companyId }),
        icon: <PieChartIcon />,
        label: t("ownership.routes.capTable"),
      },
      {
        to: getEMPath(["plans", "poolsAndPrograms"], { companyId }),
        icon: <PoolsIcon />,
        label: t("equityPlans.routes.poolsAndPrograms"),
      },
      {
        to: getEMPath(["plans", "agreements"], { companyId }),
        icon: <AgreementIcon />,
        label: t("equityPlans.routes.agreements"),
        isDisabled: !!notCompletedTooltip,
        tooltip: notCompletedTooltip,
      },
      {
        to: getEMPath(["plans", "exercising"], { companyId }),
        icon: <ExercisingIcon />,
        label: t("equityPlans.routes.exercise"),
        isDisabled: !!notCompletedTooltip,
        tooltip: notCompletedTooltip,
      },
      {
        to: getEMPath(["ownership", "financialInstruments"], { companyId }),
        icon: <EquityIcon />,
        label: t("equityPlans.routes.issueEquity"),
        isDisabled: !!notCompletedTooltip,
        tooltip: notCompletedTooltip,
      },
      {
        to: getEMPath(["ownership", "transactions"], { companyId }),
        icon: <TransactionsIcon />,
        label: t("ownership.routes.transactions"),
        isDisabled: !!notCompletedTooltip,
        tooltip: notCompletedTooltip,
      },
      {
        to: getEMPath(["ownership", "valuation"], { companyId }),
        icon: <TaxIcon />,
        label: t("ownership.routes.valuation"),
      },
      {
        to: getEMPath(["ownership", "shareClasses"], { companyId }),
        icon: <ShareClassesIcon />,
        label: t("ownership.routes.shareClasses"),
      },
      {
        to: getEMPath(["ownership", "stakeholdersManagement"], { companyId }),
        icon: <UsersIcon />,
        label: t("ownership.routes.stakeholdersManagement"),
      },
      {
        to: getEMPath(["ownership", "documents"], { companyId }),
        icon: <DocumentsIcon />,
        label: t("ownership.routes.documents"),
      },
      {
        to: getFundraisingPath([], { companyId }),
        icon: <FundraiseIcon />,
        label: t("fundraising.routes.fundraising"),
      },
    ];
  }, [companyId, notCompletedTooltip]);

  const companySettings = useMemo(() => {
    return [
      {
        to: getEMPath(["settings", "companyInformation"], { companyId }),
        icon: <InformationCircleOutlinedIcon />,
        label: t("settings.routes.companyInformation"),
      },
      {
        to: getEMPath(["settings", "bankDetails"], { companyId }),
        icon: <BankIcon />,
        label: t("settings.routes.bankDetails"),
      },
      {
        to: getEMPath(["settings", "accessControl"], { companyId }),
        icon: <AccessIcon />,
        label: t("settings.routes.accessControl"),
      },

      ...companyMenus,
    ];
  }, [companyId, companyMenus]);

  return { equityManagement, companySettings };
};

export default useCompanyMenu;
