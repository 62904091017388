import { useEffect } from "react";

import { LoaderContent } from "common/components/atoms";
import { useStoreActions, useStoreState } from "store/store";

import ExpansionPanel from "../../../common/components/atoms/ExpansionPanel/ExpansionPanel";
import FundraiseCapTable from "./FundraiseCapTable/FundraiseCapTable";
import FundraiseTransaction from "./FundraiseTransaction/FundraiseTransaction";

const PreviewResults = () => {
  const { isGetFundraisePreviewLoading, fundraiseId } = useStoreState((state) => state.fundraisingModel);
  const getFundraisePreviewThunk = useStoreActions((actions) => actions.fundraisingModel.getFundraisePreviewThunk);

  useEffect(() => {
    if (fundraiseId) {
      getFundraisePreviewThunk(fundraiseId);
    }
  }, [fundraiseId, getFundraisePreviewThunk]);

  if (!fundraiseId) {
    return null;
  }

  return (
    <LoaderContent show={isGetFundraisePreviewLoading}>
      <ExpansionPanel name={"fundraisingPreview"} defaultActiveKey={["1"]} saveExpandedKeys={true}>
        <FundraiseTransaction />

        <div className="mt-3">
          <FundraiseCapTable />
        </div>
      </ExpansionPanel>
    </LoaderContent>
  );
};

export default PreviewResults;
