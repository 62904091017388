import { useCallback, useState } from "react";

import { ExpansionPanel, ToggleButton, Ui } from "common/components/atoms";

import CapTableActual from "./CapTableActual/CapTableActual";
import CapTablePreview from "./CapTablePreview/CapTablePreview";

const FundraiseCapTable = () => {
  const [activeCapTable, setActiveCapTable] = useState("preview");

  const handleToggle = useCallback((value: string | number) => {
    setActiveCapTable(value as string);
  }, []);

  return (
    <div>
      <ExpansionPanel.Toggle eventKey="1">
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <Ui.xl bold color="foregroundHigh">
              Cap table
            </Ui.xl>
            <Ui.xs style={{ marginTop: 4 }} color="foregroundLow">
              Preview of the cap table resulting from this campaign
            </Ui.xs>
          </div>
        </div>
      </ExpansionPanel.Toggle>
      <ExpansionPanel.Collapse eventKey="1">
        <div className="px-4 pb-4">
          {/*<DashboardTable table={table} />*/}
          <ToggleButton
            firstVariant={{
              title: "Preview",
              value: "preview",
            }}
            secondVariant={{
              title: "Actual",
              value: "actual",
            }}
            selected={activeCapTable}
            onClick={handleToggle}
          />
          <div className="mb-3" />
          {activeCapTable === "preview" ? <CapTablePreview /> : <CapTableActual />}
        </div>
      </ExpansionPanel.Collapse>
    </div>
  );
};

export default FundraiseCapTable;
