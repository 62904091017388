import { FC, useCallback } from "react";
import { useFormikContext } from "formik";

import { PoolFormValues } from "pages/equity-management/plans/wizards/create-pool/usePoolForm";
import { createTranslation, TranslationNS } from "translation";

import FileUploader, { FilesDataMultiple, UploadedFile } from "../../../components/atoms/FileUploader/FileUploader";

export type LabelFieldProps = {
  poolId?: number;
  className?: string;
};

const t = createTranslation(TranslationNS.common, "pool.components.poolForm.documentsField");

const PoolDocumentsField: FC<LabelFieldProps> = ({ className }) => {
  const { values, setFieldValue, errors, touched } = useFormikContext<PoolFormValues>();

  const handleChange = useCallback(
    (data: FilesDataMultiple) => {
      setFieldValue("filesData", {
        files: data.newFiles,
        oldFiles: values.filesData.oldFiles,
      });
    },
    [setFieldValue, values.filesData.oldFiles]
  );

  return (
    <FileUploader
      prevFileData={values.filesData.oldFiles as UploadedFile[]}
      label={t("label")}
      multiple
      className={className}
      onChange={handleChange}
      isTouched={touched?.filesData as boolean | undefined}
      error={errors.filesData?.files as string}
    />
  );
};

export default PoolDocumentsField;
