import { FC } from "react";
import { Form, useFormikContext } from "formik";

import { Button, SlidePanel } from "common/components/atoms";
import { DocumentStatusField, UploadDocumentsField } from "common/components/molecules/formikFields";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { createTranslation, TranslationNS } from "translation";

import AmountToRiseField from "./fields/AmountToRiseField";
import CompanyValuationField from "./fields/CompanyValuationField";
import EndDateField from "./fields/EndDateField";
import EventNameField from "./fields/EventNameField";
import StartDateField from "./fields/StartDateField";

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.fundraising");

type FundraisingFormProps = {
  status: "start" | "update";
};
const SetFundraiseForm: FC<FundraisingFormProps> = ({ status }) => {
  const currentValuation = 30000000;

  const { isSubmitting } = useFormikContext();

  return (
    <Form>
      <ToastFormikValidator />
      <SlidePanel.Section title={t("basicInformation")}>
        <AmountToRiseField />
        <CompanyValuationField className="mt-4" currentValuation={currentValuation} />
        <EventNameField className="mt-4" />
      </SlidePanel.Section>

      <SlidePanel.Section title={t("fundraisingTimeline")}>
        <StartDateField />
        <EndDateField className="mt-4" />
      </SlidePanel.Section>

      <SlidePanel.Section title={"Documentation"}>
        <UploadDocumentsField />
        <DocumentStatusField />
      </SlidePanel.Section>

      <SlidePanel.Actions>
        <Button type="submit" variant="primary" className="me-3" isLoading={isSubmitting} isDisabled={isSubmitting}>
          {t(`save_${status}`)}
        </Button>
        <Button type="button" variant="secondary" isDisabled={isSubmitting}>
          {t("cancel")}
        </Button>
      </SlidePanel.Actions>
    </Form>
  );
};

export default SetFundraiseForm;
