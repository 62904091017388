import { FC, useCallback } from "react";
import { Col, Row } from "react-bootstrap";
import { useFormikContext } from "formik";

import { DatePicker, DatePickerProps, Helper } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import { fields, FundraisingFormType } from "../useFundraisingSlidePanel";

type AmountToRiseFieldProps = {
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.fundraising.fields");

const EndDateField: FC<AmountToRiseFieldProps> = ({ className }) => {
  const { values, setFieldValue, handleBlur, errors, touched } = useFormikContext<FundraisingFormType>();

  const handleChange = useCallback<DatePickerProps["onChange"]>(
    (date, name) => {
      if (name) {
        setFieldValue(name, date);
      }
    },
    [setFieldValue]
  );

  return (
    <Row className={className}>
      <Col>
        <Helper>
          <Helper.Question type="input" questionId={fields.endsAt}>
            <DatePicker
              name={fields.endsAt}
              label={t("endDate")}
              date={values.endsAt}
              onChange={handleChange}
              onBlur={handleBlur}
              error={errors.endsAt}
              isTouched={touched.endsAt}
              isDateOnlyString
            />
          </Helper.Question>
          <Helper.Answer className="mt-3" withRightMargin answerId={fields.endsAt} text={"Answer"} />
        </Helper>
      </Col>
    </Row>
  );
};
export default EndDateField;
