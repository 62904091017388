import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

import { Button, LoaderContainer, SlidePanel } from "common/components/atoms";
import { FundraisingInstrumentsGetDTO } from "store/modelTypes";
import { useStoreActions } from "store/store";

import { InstrumentTableContext, InstrumentTableContextType } from "./AddInstrumentsSlidePanel";
import InstrumentsTable from "./InstrumentsTable";

type InstrumentSidePanelFormProps = {
  activeFundraiseRoundId: number;
  companyId: number;
  fundingRoundTypeId: number;
  onHide: () => void;
  setUpdateFundraise: Dispatch<SetStateAction<boolean>>;
};

const AddInstrumentsForm: FC<InstrumentSidePanelFormProps> = ({
  activeFundraiseRoundId,
  companyId,
  fundingRoundTypeId,
  onHide,
  setUpdateFundraise,
}) => {
  const { getFundraisingInstrumentsThunk, setFundraisingInstrumentsThunk } = useStoreActions(
    (actions) => actions.fundraisingModel
  );
  const [instrumentData, setInstrumentData] = useState<FundraisingInstrumentsGetDTO[]>([]);

  const [isGetLoading, setIsGetLoading] = useState(false);
  const [isPostLoading, setIsPostLoading] = useState(false);

  const updateFundraiseRoundId = useCallback(
    ({ entityId, roundId }: { entityId: number; roundId: number | null }) => {
      const data = instrumentData.map((instrument) => ({
        ...instrument,
        checkboxRoundId: instrument.entityId === entityId ? roundId : instrument.checkboxRoundId,
      }));
      setInstrumentData(data);
    },
    [instrumentData]
  );

  useEffect(() => {
    setIsGetLoading(true);
    getFundraisingInstrumentsThunk({ companyId, fundingRoundTypeId })
      .then((res) => {
        setInstrumentData(
          res.data.map((instrument) => ({
            ...instrument,
            isDisabled: !!instrument.fundraiseRoundId,
            checkboxRoundId: null,
          }))
        );
      })
      .finally(() => {
        setIsGetLoading(false);
      });
  }, [companyId, fundingRoundTypeId, getFundraisingInstrumentsThunk]);

  const instruments = useMemo<InstrumentTableContextType>(() => {
    return {
      instruments: instrumentData,
      activeFundraiseRoundId,
      updateFundraiseRoundId,
    };
  }, [activeFundraiseRoundId, instrumentData, updateFundraiseRoundId]);

  const handleAddInstruments = useCallback(() => {
    // const items = instrumentData.filter((instrument) => instrument.checkboxRoundId === activeFundraiseRoundId);

    setIsPostLoading(true);
    setFundraisingInstrumentsThunk({
      fundraiseRoundId: activeFundraiseRoundId,
      instruments: instrumentData
        .filter((item) => item.checkboxRoundId === activeFundraiseRoundId)
        .map((item) => ({
          instrumentEntityType: item.entityTypeId,
          entityId: item.entityId,
        })),
    })
      .then(() => {
        setUpdateFundraise(true);
      })
      .finally(() => {
        setIsPostLoading(false);
        onHide();
      });
  }, [activeFundraiseRoundId, instrumentData, onHide, setFundraisingInstrumentsThunk, setUpdateFundraise]);

  const totalSelectedItems = useMemo(() => {
    return instrumentData.filter((instrument) => instrument.checkboxRoundId === activeFundraiseRoundId).length;
  }, [activeFundraiseRoundId, instrumentData]);

  return (
    <InstrumentTableContext.Provider value={instruments}>
      <LoaderContainer loading={isGetLoading} variant="contentScreen">
        <InstrumentsTable />
        <SlidePanel.Actions>
          <Button
            onClick={handleAddInstruments}
            isDisabled={totalSelectedItems === 0 || isPostLoading}
            isLoading={isPostLoading}
          >{`Add ${totalSelectedItems} instruments`}</Button>
          <Button onClick={onHide} className="ms-2" variant="secondary" isDisabled={isPostLoading}>
            Cancel
          </Button>
        </SlidePanel.Actions>
      </LoaderContainer>
    </InstrumentTableContext.Provider>
  );
};

export default AddInstrumentsForm;
