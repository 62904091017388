import { useMemo } from "react";
import { defaultTo, values } from "ramda";
import { number, object, string } from "yup";

import { FilesDataMultiple } from "common/components/atoms/FileUploader/FileUploader";
import { DocumentStatusEnum } from "common/enums/enum";
import { RoundGetDTO, RoundPostDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

const tv = createTranslation(TranslationNS.validation);

export const fields: { [K in keyof Required<FundingRoundFormValues>]: K } = {
  fundraiseId: "fundraiseId",
  fundingRoundId: "fundingRoundId",
  name: "name",
  description: "description",
  documentStatusId: "documentStatusId",
  filesData: "filesData",
  documentFiles: "documentFiles",
  fundraiseRoundTypeId: "fundraiseRoundTypeId",
} as const;

export type FundingRoundFormValues = RoundPostDTO & { documentFiles?: FilesDataMultiple };

const useFundingRoundSlidePanel = (data?: Partial<RoundGetDTO>) => {
  const initialValues = useMemo<FundingRoundFormValues>(
    () => ({
      [fields.name]: defaultTo("", data?.name),
      [fields.description]: defaultTo("", data?.description),
      [fields.fundraiseId]: defaultTo(0, data?.fundraiseId),
      [fields.fundingRoundId]: defaultTo(undefined, data?.fundingRoundId),
      [fields.documentStatusId]: defaultTo(DocumentStatusEnum.NO_DOCUMENT_REQUIRED, data?.documentStatusId),
      [fields.fundraiseRoundTypeId]: defaultTo(0, data?.fundraiseRoundTypeId),
      [fields.documentFiles]: data?.documentFiles
        ? { newFiles: [], oldFiles: data?.documentFiles }
        : { newFiles: [], oldFiles: [] },
    }),
    [data]
  );

  const validationSchema = useMemo(() => {
    return object().shape({
      [fields.name]: string().required(tv("required")),
      [fields.documentStatusId]: number().required(tv("required")).oneOf(values(DocumentStatusEnum), tv("required")),
      [fields.fundraiseRoundTypeId]: number().min(1, tv("required")),
      [fields.documentFiles]: object().when(fields.documentStatusId, {
        is: (documentValue: number) => +documentValue === DocumentStatusEnum.DOCUMENT_UPLOADED,
        then: object().test("documentFiles", tv("required"), (value) => {
          const val = value as unknown as FilesDataMultiple;
          return val && (val.newFiles.length > 0 || val.oldFiles.length > 0);
        }),
      }),
    });
  }, []);

  return {
    initialValues,
    validationSchema,
  };
};

export default useFundingRoundSlidePanel;
