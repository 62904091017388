import { FC, useCallback } from "react";

import { useStoreActions } from "store/store";

import DeleteInstrumentModal, {
  DeleteInstrumentModalProps,
} from "../../../components/modals/DeleteInstrumentModal/DeleteInstrumentModal";
import StakeholderViewModal from "../../../components/modals/StakeholderViewModal/StakeholderViewModal";
import SubscriptionViewInfo from "../../../components/modals/StakeholderViewModal/SubscriptionViewInfo/SubscriptionViewInfo";
import LoanConversionSlidePanel from "../../../components/slide-panels/ConversionSlidePanels/Loan/LoanConversionSlidePanel";
import NoteConversionSlidePanel from "../../../components/slide-panels/ConversionSlidePanels/Note/NoteConversionSlidePanel";
import SetNewSubscriptionSlidePanel from "../../../components/slide-panels/SetNewSubscriptionSidePanel/SetNewSubscriptionSlidePanel";

export type RoundTableActionsProps = (
  | None
  | EditSubscriptions
  | UpdateConvertibleNoteType
  | DeleteInstrumentState
  | UpdateConvertibleLoanType
  | ViewSubscriptionType
) & {
  show: boolean;
  onHide?: () => void;
  fundraiseId?: number;
};

type None = {
  type: "none";
};

type DeleteInstrumentState = Omit<DeleteInstrumentModalProps, "onHide"> & { type: "delete" };

type EditSubscriptions = {
  type: "editSubscriptions";
  sharesSubscriptionId?: number;
  fundraiseRoundId: number;
};

type UpdateConvertibleLoanType = {
  type: "editConvertibleLoan";
  convertibleLoanId?: number;
};
type UpdateConvertibleNoteType = {
  type: "editConvertibleNote";
  convertibleNoteId?: number;
};
type ViewSubscriptionType = {
  type: "viewSubscription";
  sharesSubscriptionId?: number;
  stakeholderId: number;
};

const RoundTableActions: FC<RoundTableActionsProps> = ({ show, fundraiseId, onHide, ...props }) => {
  const { getFundraiseSummaryThunk } = useStoreActions((actions) => actions.fundraisingModel);

  const handleUpdatePreview = useCallback(() => {
    getFundraiseSummaryThunk(fundraiseId || 0).then();
  }, [fundraiseId, getFundraiseSummaryThunk]);

  const handleClose = useCallback(() => {
    onHide?.();
  }, [onHide]);

  return (
    <div>
      <DeleteInstrumentModal
        {...props}
        show={show && props.type === "delete"}
        onHide={handleClose}
        onSaved={handleUpdatePreview}
      />
      <SetNewSubscriptionSlidePanel
        {...props}
        show={show && props.type === "editSubscriptions"}
        onHide={handleClose}
        fundraiseRoundId={props.type === "editSubscriptions" ? props?.fundraiseRoundId : 0}
        onSaved={handleUpdatePreview}
      />
      <LoanConversionSlidePanel
        {...props}
        show={show && props.type === "editConvertibleLoan"}
        onHide={handleClose}
        onSaved={handleUpdatePreview}
      />
      <NoteConversionSlidePanel
        {...props}
        show={show && props.type === "editConvertibleNote"}
        onHide={handleClose}
        onSaved={handleUpdatePreview}
      />
      <StakeholderViewModal
        {...props}
        stakeholderId={props.type === "viewSubscription" ? props.stakeholderId : 0}
        show={show && props.type === "viewSubscription"}
        handleClose={handleClose}
      >
        <SubscriptionViewInfo
          sharesSubscriptionId={props.type === "viewSubscription" ? props?.sharesSubscriptionId : undefined}
        />
      </StakeholderViewModal>
    </div>
  );
};

export default RoundTableActions;
