import React, { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Formik, FormikConfig } from "formik";

import { LoaderContainer } from "common/components/atoms";
import { useStoreActions, useStoreState } from "store/store";

import SetFundraiseForm from "./SetFundraiseForm";
import useFundraisingSlidePanel, { FundraisingFormType } from "./useFundraisingSlidePanel";

type SetFundraiseFormikProps = {
  onHide: () => void;
  fundraiseId?: number;
  onSaved?: () => void;
};
const SetFundraiseFormik: FC<SetFundraiseFormikProps> = ({ onHide, fundraiseId, onSaved }) => {
  const { companyId } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const { setFundraisingThunk, getFundraiseThunk } = useStoreActions((actions) => actions.fundraisingModel);
  const fundraise = useStoreState((state) => state.fundraisingModel.fundraise);

  const { initialValues, validationSchema } = useFundraisingSlidePanel(fundraise);

  const handleSubmit = useCallback<FormikConfig<FundraisingFormType>["onSubmit"]>(
    async (values) => {
      try {
        await setFundraisingThunk({ ...values, companyId });
        onSaved?.();

        onHide();
      } catch (error) {
        console.error(error);
      }
    },
    [setFundraisingThunk, companyId, onSaved, onHide]
  );

  useEffect(() => {
    if (fundraiseId) {
      setIsLoading(true);
      getFundraiseThunk(fundraiseId).finally(() => setIsLoading(false));
    }
  }, [fundraiseId, getFundraiseThunk]);

  return (
    <LoaderContainer variant="contentScreen" loading={isLoading}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        <SetFundraiseForm status={!fundraise?.fundraiseId ? "start" : "update"} />
      </Formik>
    </LoaderContainer>
  );
};

export default SetFundraiseFormik;
