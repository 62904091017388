import { FC, useCallback } from "react";
import { useFormikContext } from "formik";

import { DatePicker } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import { ConversionFormValues, fields } from "./fieldsConversion";

type ConversionDateProps = {
  className?: string;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.conversions.fields");

const ConversionDateField: FC<ConversionDateProps> = ({ className }) => {
  const { values, setFieldValue, handleBlur, errors, touched } = useFormikContext<ConversionFormValues>();

  const handleChange = useCallback(
    (date: Date | string) => {
      setFieldValue(fields.conversionDate, date).then();
    },
    [setFieldValue]
  );

  return (
    <div className={className}>
      <DatePicker
        label={t("conversionDate.label")}
        date={values.conversionDate}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.conversionDate}
        isTouched={touched.conversionDate}
        isDateOnlyString
      />
    </div>
  );
};
export default ConversionDateField;
