import React, { FC, useContext, useState } from "react";
import { getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from "@tanstack/react-table";

import { SlidePanel, Ui } from "common/components/atoms";

import DashboardTable from "../../DashboardTable/DashboardTable";
import { InstrumentTableContext } from "./AddInstrumentsSlidePanel";
import useInstrumentsTable from "./useInstrumentsTable";

type InstrumentsContentProps = {};

const InstrumentsTable: FC<InstrumentsContentProps> = () => {
  const { instruments } = useContext(InstrumentTableContext);

  const [sorting, setSorting] = useState<SortingState>([{ id: "stakeholderName", desc: false }]);

  const { columns } = useInstrumentsTable();

  const table = useReactTable({
    data: instruments,
    columns,
    state: {
      sorting,
    },
    meta: {
      footerShow: false,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div>
      <SlidePanel.Section title={"Existing instruments"}>
        <Ui.s color="foregroundHigh" className="mb-4">
          Select the financial instruments you`d like to include in this funding round. For more information about each
          item, visit the Financial Instruments section.
        </Ui.s>
        <DashboardTable table={table} />
      </SlidePanel.Section>
    </div>
  );
};

export default InstrumentsTable;
