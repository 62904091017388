import axios from "axios";
import { action, thunk } from "easy-peasy";

import {
  FundraiseGetDTO,
  FundraisePreviewGetDTO,
  FundraiseShareSubscriptionGetDTO,
  FundraiseShareSubscriptionPostDTO,
  FundraiseSummaryGetDTO,
  FundraisingInstrumentsGetDTO,
  FundraisingModel,
  RoundGetDTO,
  RoundPostDTO,
} from "./modelTypes";

export const fundraisingModel: FundraisingModel = {
  isGetFundraisingLoading: false,
  isPostFundraisingLoading: false,
  isGetFundraisePreviewLoading: false,
  isGetFundraiseRoundLoading: false,
  isGetFundraiseSubscriptionLoading: false,
  isGetFundraiseLoanConversionLoading: false,
  //state
  fundraiseId: undefined,
  fundraise: null,
  transaction: null,
  summary: null,
  instruments: [],
  fundraisePreview: null,
  fundraiseRound: null,
  //actions
  setLoadingAction: action((state, payload) => {
    state[payload.param] = payload.status;
  }),
  setFundraiseSummary: action((state, payload) => {
    state.summary = payload;
  }),
  setFundraisingInstruments: action((state, payload) => {
    state.instruments = payload;
  }),
  setFundraiseId: action((state, payload) => {
    state.fundraiseId = payload;
  }),
  setFundraisePreview: action((state, payload) => {
    state.fundraisePreview = payload;
  }),
  setFundraise: action((state, payload) => {
    state.fundraise = payload;
  }),
  setFundraiseRound: action((state, payload) => {
    state.fundraiseRound = payload;
  }),
  //thunks
  getOngoingFundraiseThunk: thunk(async (actions, companyId) => {
    try {
      const response = await axios.get<Pick<FundraisingModel, "fundraiseId">>(
        `/api/fundraising/detect-ongoing/${companyId}`
      );
      actions.setFundraiseId(response.data.fundraiseId);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }),
  getFundraiseThunk: thunk(async (actions, fundraiseId) => {
    try {
      const response = await axios.get<FundraiseGetDTO>(`/api/fundraising/fundraise/${fundraiseId}`);
      actions.setFundraise(response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }),
  getFundraiseSummaryThunk: thunk(async (actions, fundraiseId) => {
    actions.setLoadingAction({ param: "isGetFundraisingLoading", status: true });
    try {
      const response = await axios.get<FundraiseSummaryGetDTO>(`/api/fundraising/fundraise/overview/${fundraiseId}`);
      actions.setFundraiseSummary(response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      actions.setLoadingAction({ param: "isGetFundraisingLoading", status: false });
    }
  }),
  setFundraisingThunk: thunk(async (actions, payload) => {
    actions.setLoadingAction({ param: "isPostFundraisingLoading", status: true });
    try {
      return await axios.post("/api/fundraising/fundraise", payload);
    } catch (error) {
      return Promise.reject(error);
    } finally {
      actions.setLoadingAction({ param: "isPostFundraisingLoading", status: false });
    }
  }),
  setRoundThunk: thunk((_actions, payload) => {
    return axios.post<RoundPostDTO>("/api/fundraising/fundraise/round", payload);
  }),
  getFundraisingInstrumentsThunk: thunk(async (actions, { companyId, fundingRoundTypeId }) => {
    try {
      const response = await axios.get<FundraisingInstrumentsGetDTO[]>(
        `/api/fundraising/instruments/${companyId}/${fundingRoundTypeId}`
      );
      actions.setFundraisingInstruments(response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }),
  setFundraisingInstrumentsThunk: thunk(async (_actions, payload) => {
    return axios.post("/api/fundraising/fundraise/instrument", payload);
  }),
  removeInstrumentThunk: thunk((_actions, payload) => {
    return axios.delete(`/api/fundraising/instrument/${payload.instrumentEntityTypeId}/${payload.entityId}`);
  }),
  deleteRoundThunk: thunk((_actions, payload) => {
    return axios.delete(`/api/fundraising/fundraise/round/${payload}`);
  }),
  setFundraiseSubscriptionThunk: thunk(async (_actions, formValues) => {
    const formData = new FormData();

    Object.keys(formValues).forEach((key) => {
      const item = key as keyof FundraiseShareSubscriptionPostDTO;
      const allowTypes = ["number", "string", "boolean"];
      if (allowTypes.includes(typeof formValues?.[item])) {
        formData.append(key, String(formValues[item]));
      }
    });

    return axios.post("/api/fundraising/fundraise/shares-subscription", formData);
  }),
  getFundraiseSubscriptionThunk: thunk(async (actions, sharesSubscriptionId) => {
    actions.setLoadingAction({ param: "isGetFundraiseSubscriptionLoading", status: true });
    try {
      return await axios.get<FundraiseShareSubscriptionGetDTO>(
        `/api/fundraising/fundraise/shares-subscription/${sharesSubscriptionId}`
      );
    } catch (error) {
      return Promise.reject(error);
    } finally {
      actions.setLoadingAction({ param: "isGetFundraiseSubscriptionLoading", status: false });
    }
  }),
  cancelFundraiseThunk: thunk(async (_actions, fundraiseId) => {
    return axios.post("/api/fundraising/fundraise/cancel", { fundraiseId });
  }),
  getFundraisePreviewThunk: thunk(async (actions, fundraiseId) => {
    actions.setLoadingAction({ param: "isGetFundraisePreviewLoading", status: true });
    try {
      const response = await axios.get<FundraisePreviewGetDTO>(`/api/fundraising/fundraise/preview/${fundraiseId}`);
      actions.setFundraisePreview(response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      actions.setLoadingAction({ param: "isGetFundraisePreviewLoading", status: false });
    }
  }),
  getFundraiseRoundThunk: thunk(async (actions, fundraiseRoundId) => {
    actions.setLoadingAction({ param: "isGetFundraiseRoundLoading", status: true });
    try {
      const response = await axios.get<RoundGetDTO>(`/api/fundraising/fundraise/round/${fundraiseRoundId}`);
      actions.setFundraiseRound(response.data);
      return response;
    } catch (error) {
      return Promise.reject(error);
    } finally {
      actions.setLoadingAction({ param: "isGetFundraiseRoundLoading", status: false });
    }
  }),
  getFundraiseLoanConversionThunk: thunk(async (_actions, convertibleLoanId) => {
    return axios.get(`/api/fundraising/fundraise/loan-conversion/${convertibleLoanId}`);
  }),
  getFundraiseNoteConversionThunk: thunk(async (_actions, convertibleNoteId) => {
    return axios.get(`/api/fundraising/fundraise/note-conversion/${convertibleNoteId}`);
  }),
};
