import { useState } from "react";
import { Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import { isNil, or } from "ramda";

import { Button, DragDrop, SlidePanel, TextField } from "common/components/atoms";
import FileItem, { FileItemStatuses } from "common/components/atoms/FileItem/FileItem";
import { createTranslation, TranslationNS } from "translation";

import DocumentsContext from "../../../DocumentsContext";
import classes from "../../CommonStyles.module.scss";

const t = createTranslation(TranslationNS.pages, "company.documents");

const FinancialInstrumentsPanelContext = () => {
  const { companyId } = useParams<{ companyId: string }>();

  const { isLoading, selectedSingleFinancialInstrument } = DocumentsContext.useStoreState((state) => state);
  const { setIsLoading, setIsEditPanelOpen, setSelectedSingleFinancialInstrument, getCompanyDocumentsThunk } =
    DocumentsContext.useStoreActions((actions) => actions);

  const [selectedDocument, setSelectedDocument] = useState<File | null>(null);
  const [documentName, setDocumentName] = useState<string>();

  const onDrop = (e: File) => {
    setSelectedDocument(e);
  };

  const handleClose = () => {
    setIsEditPanelOpen({
      isOpen: false,
    });

    setSelectedSingleFinancialInstrument(null);
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const abortController = new AbortController();
      const formData = new FormData();

      formData.append("File", selectedDocument as File);
      formData.append("CompanyFileType", String(selectedSingleFinancialInstrument?.documentType));
      formData.append("FileName", String(documentName ? documentName : selectedDocument?.name));
      formData.append("EntityId", String(selectedSingleFinancialInstrument?.entityId));

      const request = await axios.post(`/api/document/upload-company-document/${companyId}`, formData, {
        signal: abortController.signal,
      });

      if (request.status === 200) {
        getCompanyDocumentsThunk(Number(companyId));

        handleClose();
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <SlidePanel.Header
        title={t("financialInstrumentsTable.tableTitle", {
          name: selectedSingleFinancialInstrument?.name,
        })}
        onHide={handleClose}
      />
      <SlidePanel.Section className={classes["slide-panel"]} title={t("documentation")} isSingle>
        <DragDrop
          withoutBrowse
          label={t("documentsInputFile.label")}
          customEndLabelContent={t("documentsInputFile.onlyPdf")}
          content={
            t.el("documentsInputFile.content", {
              components: [
                <span
                  style={{
                    textDecoration: "underline",
                  }}
                  key={1}
                />,
              ],
            }) as unknown as string
          }
          onChange={onDrop}
        />

        <AnimatePresence>
          {selectedDocument && (
            <motion.div
              exit={{ opacity: 0, y: "-100%", height: 0 }}
              animate={{ opacity: 1, y: 0, height: "auto" }}
              initial={{
                height: 0,
                opacity: 0,
                y: "-100%",
              }}
            >
              <FileItem className="mt-1" file={selectedDocument} status={FileItemStatuses.uploaded} />
            </motion.div>
          )}
        </AnimatePresence>

        <Col xs={6}>
          <TextField
            className="mt-5"
            label={t("addFileName")}
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
          />
        </Col>
      </SlidePanel.Section>

      <SlidePanel.Actions className={classes["slide-actions"]}>
        <Button isDisabled={or(isLoading, isNil(selectedDocument))} onClick={handleSubmit}>
          {t("financialInstrumentsTable.uploadAgreement")}
        </Button>

        <Button className="ms-3" variant="secondary" onClick={handleClose}>
          {t("cancel")}
        </Button>
      </SlidePanel.Actions>
    </>
  );
};

export default FinancialInstrumentsPanelContext;
