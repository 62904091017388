import { FC } from "react";
import classNames from "classnames";
import { equals } from "ramda";

import { Ui } from "common/components/atoms";
import { EquityIcon, PieChartIcon, TransactionsIcon } from "common/icons/svg";

import classes from "./ShareholderDetailsModal.module.scss";

export enum ShareholderSelectedTabOptions {
  "ownership" = "ownership",
  "instruments" = "instruments",
  "transactions" = "transactions",
}

type Props = {
  transactionsCount: number;
  financialInstrumentsCount: number;
  selectedTab: ShareholderSelectedTabOptions;
  setSelectedTab: (tab: ShareholderSelectedTabOptions) => void;
};

const ShareholderTabsHead: FC<Props> = ({
  financialInstrumentsCount,
  selectedTab,
  transactionsCount,
  setSelectedTab,
}) => {
  return (
    <div
      className={classNames(classes["tabs-head"], {
        [classes["ownership"]]: equals(selectedTab, ShareholderSelectedTabOptions.ownership),
        [classes["instruments"]]: equals(selectedTab, ShareholderSelectedTabOptions.instruments),
        [classes["transactions"]]: equals(selectedTab, ShareholderSelectedTabOptions.transactions),
      })}
    >
      <div
        className={classNames(classes["tab-item"], {
          [classes["active"]]: equals(selectedTab, ShareholderSelectedTabOptions.ownership),
        })}
        onClick={() => {
          setSelectedTab(ShareholderSelectedTabOptions.ownership);
        }}
      >
        <PieChartIcon />
        <Ui.xs className="ms-1">Ownership</Ui.xs>
      </div>

      <div
        className={classNames(classes["tab-item"], {
          [classes["active"]]: equals(selectedTab, ShareholderSelectedTabOptions.instruments),
        })}
        onClick={() => {
          setSelectedTab(ShareholderSelectedTabOptions.instruments);
        }}
      >
        <EquityIcon />
        <Ui.xs className="ms-1"> Financial instruments</Ui.xs>

        <Ui.xs
          className={classNames(classes["badge"], {
            [classes["active"]]: equals(selectedTab, ShareholderSelectedTabOptions.instruments),
          })}
        >
          {financialInstrumentsCount}
        </Ui.xs>
      </div>

      <div
        className={classNames(classes["tab-item"], {
          [classes["active"]]: equals(selectedTab, ShareholderSelectedTabOptions.transactions),
        })}
        onClick={() => {
          setSelectedTab(ShareholderSelectedTabOptions.transactions);
        }}
      >
        <TransactionsIcon />
        <Ui.xs className="ms-1">Transactions</Ui.xs>

        <Ui.xs
          className={classNames(classes["badge"], {
            [classes["active"]]: equals(selectedTab, ShareholderSelectedTabOptions.transactions),
          })}
        >
          {transactionsCount}
        </Ui.xs>
      </div>
    </div>
  );
};

export default ShareholderTabsHead;
