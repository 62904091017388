import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { Form, useFormikContext } from "formik";

import { Helper } from "common/components/atoms";
import Button from "common/components/atoms/Button/Button";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import { InstrumentTypesIdsEnum } from "common/enums/enum";
import useToastFormikValidator from "common/hooks/useToastFormikValidator";
import DocumentStatusField from "common/program/components/ProgramForm/DocumentStatusField";
import TerminationFields from "common/program/components/ProgramForm/TerminationFields";
import VestingDetails from "common/program/components/ProgramForm/VestingDetails/VestingDetails";
import { ProgramStatuses } from "common/types/Program";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import ProgramHelper from "../../../wizards/create-program/components/ProgramHelper";
import ProgramApprovalDateField from "../../../wizards/create-program/programFields/ProgramApprovalDateField";
import ProgramFieldDataField from "../../../wizards/create-program/programFields/ProgramFieldDataField";
import ProgramSigningManagerField from "../../../wizards/create-program/programFields/ProgramSigningManagerField";
import { CreateProgramFormData, fields } from "../../../wizards/create-program/useProgramForm";
import ProgramDetailsFields from "./FormContent/ProgramDetailsFields/ProgramDetailsFields";
import ShareDetailsFields from "./FormContent/ShareDetailsFields/ShareDetailsFields";

const t = createTranslation(TranslationNS.pages, "company.poolsAndPrograms.editProgram");

enum FormAccordionKeys {
  programDetails = "programDetails",
  sharesDetails = "sharesDetails",
  vestingDetails = "vestingDetails",
  termination = "termination",
  documents = "documents",
}

const EditProgramFormContent = () => {
  const { companyId } = useParams();
  const program = useStoreState((state) => state.programModel.program);

  const { values, setFieldValue, isSubmitting } = useFormikContext<CreateProgramFormData>();

  const handleSaveDraft = useCallback(() => {
    setFieldValue(fields.submitter, "draft");
  }, [setFieldValue]);

  const handleSaveAndApprove = useCallback(() => {
    setFieldValue(fields.submitter, "approve");
  }, [setFieldValue]);

  useToastFormikValidator();

  return (
    <Form>
      <SlidePanel.Section title={t("programDetails")}>
        <ProgramDetailsFields />
      </SlidePanel.Section>

      <SlidePanel.Section title={t("sharesDetails")}>
        <ShareDetailsFields
          poolIsDisabled={program?.ownershipProgramStatusId === ProgramStatuses.publish}
          companyId={companyId}
        />
      </SlidePanel.Section>

      <SlidePanel.Section title={t(FormAccordionKeys.vestingDetails)}>
        <VestingDetails type="program" />
        <Helper>
          <ProgramHelper questionId={"approvalDate"} type="input" colInputSize={6}>
            <ProgramApprovalDateField />
          </ProgramHelper>
        </Helper>
      </SlidePanel.Section>

      {+values.programTypeId === InstrumentTypesIdsEnum.OPTION && (
        <SlidePanel.Section title={t("termination")}>
          <TerminationFields />
        </SlidePanel.Section>
      )}

      <SlidePanel.Section title={t("documents")} isDividerVisible={false}>
        <div className="mb-4">
          <ProgramFieldDataField />
        </div>
        <DocumentStatusField />
        <Helper>
          <ProgramHelper className="mt-4" questionId={"signingManager"} type="input" colInputSize={6}>
            <ProgramSigningManagerField />
          </ProgramHelper>
        </Helper>
      </SlidePanel.Section>

      <SlidePanel.Actions>
        <Button
          type="submit"
          isLoading={isSubmitting && values.submitter === "draft"}
          isDisabled={isSubmitting}
          onClick={handleSaveDraft}
        >
          {t("button.saveChanges")}
        </Button>
        {program?.ownershipProgramStatusId === ProgramStatuses.draft && (
          <Button
            type="submit"
            className="ms-3"
            variant="secondary"
            isLoading={isSubmitting && values.submitter === "approve"}
            isDisabled={isSubmitting}
            onClick={handleSaveAndApprove}
          >
            {t("button.create")}
          </Button>
        )}
      </SlidePanel.Actions>
    </Form>
  );
};

export default EditProgramFormContent;
