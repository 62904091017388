import { ChangeEvent, FC, useCallback, useContext } from "react";
import { FormGroup } from "react-bootstrap";

import Check from "common/components/atoms/Checks/Check";

import { Tooltip, Ui } from "../../../../../../../common/components/atoms";
import { InformationCircleOutlinedIcon } from "../../../../../../../common/icons/svg";
import { InstrumentTableContext } from "../../AddInstrumentsSlidePanel";

type SelectCheckboxProps = {
  fundraiseRoundId?: number | null;
  entityId: number;
  isDisabled?: boolean;
  fundraiseRoundName: string;
};
const SelectCheckbox: FC<SelectCheckboxProps> = ({ entityId, fundraiseRoundId, isDisabled, fundraiseRoundName }) => {
  const { activeFundraiseRoundId, updateFundraiseRoundId } = useContext(InstrumentTableContext);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      updateFundraiseRoundId({ entityId, roundId: fundraiseRoundId !== +e.target.value ? +e.target.value : null });
    },
    [entityId, fundraiseRoundId, updateFundraiseRoundId]
  );

  return (
    <>
      {!isDisabled ? (
        <FormGroup>
          <Check
            type="checkbox"
            checked={activeFundraiseRoundId === fundraiseRoundId}
            value={activeFundraiseRoundId}
            onChange={handleChange}
            disabled={isDisabled}
          />
        </FormGroup>
      ) : (
        <Tooltip
          popupContent={
            <div style={{ width: 172, textAlign: "left" }}>Instrument was added to “{fundraiseRoundName}”.</div>
          }
        >
          <div>
            <Ui.m color="foregroundLow" style={{ cursor: "pointer" }}>
              <InformationCircleOutlinedIcon fontSize={24} />
            </Ui.m>
          </div>
        </Tooltip>
      )}
    </>
  );
};

export default SelectCheckbox;
