import React, { FC, useCallback, useState } from "react";

import { SlidePanel } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

import NoteConversionFormik from "./NoteConversionFormik";

type Props = {
  show: boolean;
  onHide: () => void;
  onSaved?: () => void;
  convertibleNoteId?: number;
};

const t = createTranslation(TranslationNS.pages, "fundraising.sidePanels.conversions.notePanel");

const NoteConversionSlidePanel: FC<Props> = ({ show, onHide, convertibleNoteId, onSaved }) => {
  const [isSaved, setSiSaved] = useState(false);

  const handleSave = useCallback(() => {
    setSiSaved(true);
  }, []);

  const handleOnExited = useCallback(() => {
    if (isSaved) {
      onSaved?.();
      setSiSaved(false);
    }
  }, [onSaved, isSaved]);

  return (
    <SlidePanel show={show} onExited={handleOnExited}>
      <SlidePanel.Header title={t("title")} onHide={onHide} />
      <NoteConversionFormik onHide={onHide} onSaved={handleSave} convertibleNoteId={convertibleNoteId} />
    </SlidePanel>
  );
};
export default NoteConversionSlidePanel;
