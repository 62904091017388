import { useMemo } from "react";
import { defaultTo } from "ramda";
import { number, object, string } from "yup";

import { FundraiseGetDTO, FundraisingPostDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

export type FundraisingFormType = Partial<FundraisingPostDTO> & {};

export const fields = {
  fundraiseId: "fundraiseId",
  eventName: "eventName",
  targetAmount: "targetAmount",
  preMoneyValuation: "preMoneyValuation",
  maxDilution: "maxDilution",
  startsAt: "startsAt",
  endsAt: "endsAt",
} as const;

const tv = createTranslation(TranslationNS.validation);
const useFundraisingSlidePanel = (fundraise?: FundraiseGetDTO | null) => {
  const initialValues = useMemo<FundraisingFormType>(
    () => ({
      [fields.fundraiseId]: defaultTo(undefined, fundraise?.fundraiseId),
      [fields.eventName]: defaultTo("", fundraise?.eventName),
      [fields.targetAmount]: defaultTo(undefined, fundraise?.targetAmount),
      [fields.preMoneyValuation]: defaultTo(undefined, fundraise?.preMoneyValuation),
      [fields.startsAt]: defaultTo("", fundraise?.startsAt),
      [fields.endsAt]: defaultTo("", fundraise?.endsAt),
    }),
    [fundraise]
  );

  const validationSchema = useMemo(
    () =>
      object().shape({
        [fields.targetAmount]: number()
          .required(tv("required"))
          .min(1, tv("minNumber", { number: 1 }))
          .integer(tv("integer")),
        [fields.preMoneyValuation]: number()
          .required(tv("required"))
          .min(1, tv("minNumber", { number: 1 }))
          .integer(tv("integer")),
        [fields.eventName]: string().required(tv("required")),
        [fields.startsAt]: string().required(tv("required")),
        [fields.endsAt]: string().required(tv("required")),
      }),
    []
  );

  return { initialValues, validationSchema };
};

export default useFundraisingSlidePanel;
