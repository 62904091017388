import { useContext, useEffect, useState } from "react";
import { Accordion, AccordionContext, AccordionProps } from "react-bootstrap";

import Collapse from "./Collapse";
import Toggle from "./Toggle";
import ToggleIgnore from "./ToggleIgnore";

type ConditionalName<T extends boolean> = T extends true ? { name: string } : { name?: string };

type ExpansionPanelFields<T extends boolean = false> = AccordionProps &
  ConditionalName<T> & {
    saveExpandedKeys?: T;
  };

type ExpansionPanelFunction = { <T extends boolean>(data: ExpansionPanelFields<T>): JSX.Element | null };

type ExpansionPanelProps = ExpansionPanelFunction & {
  Toggle: typeof Toggle;
  ToggleIgnore: typeof ToggleIgnore;
  Collapse: typeof Collapse;
};

const prefix = "ExpansionPanel_";
const ExpansionPanel: ExpansionPanelProps = ({ children, defaultActiveKey, saveExpandedKeys, name, ...props }) => {
  const [defaultActiveKeys, setDefaultActiveKeys] = useState<null | string[] | string>(null);

  useEffect(() => {
    if (saveExpandedKeys && name) {
      const keys = localStorage.getItem(prefix + name);

      setDefaultActiveKeys(keys ? JSON.parse(keys) : defaultActiveKey);
    } else {
      setDefaultActiveKeys(defaultActiveKey || []);
    }
  }, [defaultActiveKey, name, saveExpandedKeys]);

  if (!defaultActiveKeys) return null;

  return (
    <Accordion {...props} defaultActiveKey={defaultActiveKeys} alwaysOpen>
      {children}
      {saveExpandedKeys && name && <ExpansionPanelSaver name={name} saveExpandedKeys={saveExpandedKeys} />}
    </Accordion>
  );
};

const ExpansionPanelSaver = ({ name, saveExpandedKeys }: { name?: string; saveExpandedKeys?: boolean }) => {
  const { activeEventKey } = useContext(AccordionContext);

  useEffect(() => {
    if (saveExpandedKeys && name) {
      localStorage.setItem(prefix + name, JSON.stringify(activeEventKey));
    }
  }, [activeEventKey, name, saveExpandedKeys]);

  return null;
};

ExpansionPanel.Toggle = Toggle;
ExpansionPanel.ToggleIgnore = ToggleIgnore;
ExpansionPanel.Collapse = Collapse;

export default ExpansionPanel;
