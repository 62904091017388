import React, { FC, useCallback, useEffect, useMemo } from "react";
import { Formik } from "formik";

import { SlidePanel } from "common/components/atoms";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";

import SetFundingRoundForm from "./SetFundingRoundForm";
import useFundingRoundSlidePanel, { FundingRoundFormValues } from "./useFundingRoundSlidePanel";

type SetFundingRoundFormikProps = {
  fundraiseId?: number;
  fundraiseRoundId?: number;
  onSaved?: () => void;
  onHide: () => void;
};

const SetFundingRoundFormik: FC<SetFundingRoundFormikProps> = ({ fundraiseId, onSaved, onHide, fundraiseRoundId }) => {
  const { setRoundThunk, getFundraiseRoundThunk } = useStoreActions((actions) => actions.fundraisingModel);
  const { isGetFundraiseRoundLoading } = useStoreState((state) => state.fundraisingModel);
  const fundraiseRound = useStoreState((state) => state.fundraisingModel?.fundraiseRound);

  const roundData = useMemo(() => {
    return fundraiseRoundId && fundraiseRound ? fundraiseRound : { fundraiseId };
  }, [fundraiseRoundId, fundraiseRound, fundraiseId]);

  const { initialValues, validationSchema } = useFundingRoundSlidePanel(roundData);

  const handleSubmit = useCallback(
    async (values: FundingRoundFormValues) => {
      const filesData = {
        files: values.documentFiles?.newFiles || [],
        oldFileIds: values.documentFiles?.oldFiles.map((file) => file.fileId) || [],
      };
      delete values.documentFiles;
      await setRoundThunk({
        ...values,
        filesData,
      });

      notify(`Round successfully ${values.fundingRoundId ? "updated" : "created"}`, true, "success");
      onSaved?.();
      onHide();
    },
    [onHide, onSaved, setRoundThunk]
  );

  useEffect(() => {
    if (fundraiseRoundId) {
      getFundraiseRoundThunk(fundraiseRoundId).then();
    }
  }, [getFundraiseRoundThunk, fundraiseRoundId]);

  return (
    <SlidePanel.Body isLoading={isGetFundraiseRoundLoading}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        <SetFundingRoundForm onCancel={onHide} />
      </Formik>
    </SlidePanel.Body>
  );
};
export default SetFundingRoundFormik;
