import React, { FC, useCallback, useEffect, useState } from "react";
import { Formik } from "formik";

import { SlidePanel } from "common/components/atoms";
import { notify } from "common/utils/notify/notifyFunction";
import { FundraiseLoanConversionGetDTO } from "store/modelTypes";
import { useStoreActions } from "store/store";

import LoanConversionForm from "./LoanConversionForm";
import useLoanConversionSlidePanel, { LoanConversionFormValues } from "./useLoanConversionSlidePanel";

type Props = {
  convertibleLoanId?: number;
  onSaved?: () => void;
  onHide: () => void;
};

const LoanConversionFormik: FC<Props> = ({ convertibleLoanId, onSaved, onHide }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loanConversion, setLoanConversion] = useState<FundraiseLoanConversionGetDTO | null>(null);

  const { initialValues, validationSchema } = useLoanConversionSlidePanel(loanConversion);

  const { getFundraiseLoanConversionThunk } = useStoreActions((actions) => actions.fundraisingModel);

  const handleSubmit = useCallback(
    async (values: LoanConversionFormValues) => {
      const filesData = {
        files: values.documentFiles?.newFiles || [],
        oldFileIds: values.documentFiles?.oldFiles.map((file) => file.fileId) || [],
      };
      delete values.documentFiles;

      notify(`Round successfully ${values.loanId ? "updated" : "created"}`, true, "success");
      onSaved?.();
      onHide();
    },
    [onHide, onSaved]
  );

  useEffect(() => {
    if (convertibleLoanId) {
      setIsLoading(true);
      getFundraiseLoanConversionThunk(convertibleLoanId)
        .then((res) => {
          setLoanConversion(res.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [convertibleLoanId, getFundraiseLoanConversionThunk]);

  return (
    <SlidePanel.Body isLoading={isLoading}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
        validationSchema={validationSchema}
      >
        <LoanConversionForm onCancel={onHide} convertible={loanConversion} />
      </Formik>
    </SlidePanel.Body>
  );
};
export default LoanConversionFormik;
