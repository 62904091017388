import { FC, useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, FormikConfig } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { useTransactionsService } from "../../../../../transactions-service";
import { TransactionStatus } from "../../../../../types";
import CapitalIncreaseContext from "../../capital-increase-context";
import classes from "./import-transaction.module.scss";
import TransactionActions from "./TransactionActions/TransactionActions";
import TransactionModal from "./TransactionModal/TransactionModal";
import TransactionTable from "./TransactionTable/TransactionTable";
import useImportTransactionColumns from "./use-import-transaction-columns";
import useImportTransactionForm, { ImportTransactionFormValues, initValues } from "./use-import-transaction-form";

const t = createTranslation(TranslationNS.pages, "company.transactions.capitalIncreaseForm.transactions");

const CapitalIncreaseTransactions: FC = () => {
  const navigate = useNavigate();
  const { companyId = "0" } = useParams<{ companyId?: string }>();
  const [show, setShow] = useState(false);
  const { isLoading, currentBundle, transactionsInProgress } = CapitalIncreaseContext.useStoreState((state) => state);
  const { setIsLoading, setStep } = CapitalIncreaseContext.useStoreActions((actions) => actions);
  const { postCapitalIncreaseTransactionsData } = useTransactionsService(companyId);

  const transaction = useStoreState((state) => state.companyOnboardingModel.transaction);

  const transactionTypesIssuing = useStoreState((state) => state.common.dropdowns)?.transactionTypesIssuing;

  const { getTransactionsThunk } = useStoreActions((actions) => actions.companyOnboardingModel);

  const isBundleConfirmed = currentBundle.statusId === TransactionStatus.Confirmed;

  const { personValidationSchema, data } = useImportTransactionForm(currentBundle, transactionsInProgress);

  const fetchDropdownsData = useCallback(async () => {
    setIsLoading(true);
    getTransactionsThunk(Number(companyId)).catch((e) => {
      console.error(e);
    });
    setIsLoading(false);
  }, [companyId, getTransactionsThunk, setIsLoading]);

  const columns = useImportTransactionColumns(
    fetchDropdownsData,
    transaction?.shareClasses,
    transactionTypesIssuing,
    transaction?.stakeholders
  );

  const handleSubmit = useCallback<FormikConfig<ImportTransactionFormValues[]>["onSubmit"]>(
    async (values, { setSubmitting }) => {
      try {
        if (companyId) {
          if (!show) {
            setShow(true);
            setSubmitting(false);
            return;
          }

          const deletedTransactions = currentBundle.transactions.filter(
            (oldTransaction) =>
              !values.some((newTransaction) => oldTransaction.transactionId === newTransaction.transactionId)
          );

          const deletedTransactionsIds = deletedTransactions.reduce((acc: number[], item) => {
            // TS complains about transactionId can be undefined
            if (item.transactionId) {
              acc.push(item.transactionId);
            }

            return acc;
          }, []);

          const shareIssuesTypeId = 2;
          values = values.map((item) => ({
            ...item,
            transactionType: item?.transactionType ? item?.transactionType : shareIssuesTypeId,
          }));

          const success = await postCapitalIncreaseTransactionsData({
            companyId: Number(companyId),
            transactionBundleId: currentBundle?.transactionBundleId || 0,
            transactions: values,
            transactionIdsToDelete: deletedTransactionsIds,
          });

          if (success) {
            notify(t("notifyCreated"), true, "success");

            navigate(getEMPath(["ownership", "transactions"], { companyId }));
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    [
      companyId,
      currentBundle?.transactionBundleId,
      currentBundle.transactions,
      navigate,
      postCapitalIncreaseTransactionsData,
      show,
    ]
  );

  const handleManageShow = useCallback(() => {
    setShow((prev) => !prev);
  }, []);

  useEffect(() => {
    if (companyId) {
      setIsLoading(true);
      getTransactionsThunk(Number(companyId)).catch((e) => {
        console.error(e);
      });
      setIsLoading(false);
    }
  }, [companyId, getTransactionsThunk, setIsLoading]);

  useEffect(() => {
    setStep(2);
  }, [setStep]);

  return (
    <Formik initialValues={data} onSubmit={handleSubmit} validationSchema={personValidationSchema} enableReinitialize>
      <div className={classes.wrap}>
        <TransactionActions />
        {isLoading ? (
          <Spinner />
        ) : (
          <TransactionTable
            columns={columns}
            initialItems={initValues(currentBundle.sharePrice, currentBundle.transactedAt)}
          />
        )}
        {show && <TransactionModal show={show} handleClose={handleManageShow} />}
      </div>
    </Formik>
  );
};

export default CapitalIncreaseTransactions;
