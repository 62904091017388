import { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Button, LoaderContainer } from "common/components/atoms";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import FundraiseMenu from "./components/FundraiseMenu/FundraiseMenu";
import CancelFundraiseModal from "./components/modals/CancelFundraiseModal/CancelFundraiseModal";
import SetFundraiseSlidePanel from "./components/slide-panels/SetFundraisingSlidePanel/SetFundraiseSlidePanel";
import Kickstart from "./overview/components/Kickstart/Kickstart";

type FundraisingProps = {
  children: ReactNode;
};

const t = createTranslation(TranslationNS.pages, "fundraising");
const Fundraising: FC<FundraisingProps> = ({ children }) => {
  const { companyId } = useParams();

  const [loading, setLoading] = useState(true);

  const { getOngoingFundraiseThunk, getFundraiseThunk } = useStoreActions((actions) => actions.fundraisingModel);
  const { fundraiseId } = useStoreState((state) => state.fundraisingModel);

  const [showFundraiseSlidePanel, setShowFundraiseSlidePanel] = useState(false);
  const [showCancelFundraiseModal, setShowCancelFundraiseModal] = useState(false);

  const handleManageFundraiseSlidePanel = useCallback(() => {
    setShowFundraiseSlidePanel((prev) => !prev);
  }, []);

  const handleCancelFundraiseModal = useCallback(() => {
    setShowCancelFundraiseModal((prev) => !prev);
  }, []);

  const handleLoadPage = useCallback(() => {
    setLoading(true);
    getOngoingFundraiseThunk(companyId ? +companyId : 0)
      .then((res) => {
        if (res.data.fundraiseId) {
          getFundraiseThunk(res.data.fundraiseId).finally(() => setLoading(false));
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, [companyId, getFundraiseThunk, getOngoingFundraiseThunk]);

  useEffect(() => {
    handleLoadPage();
  }, [handleLoadPage]);

  return (
    <PageContent style={{ minWidth: "600px", width: "100%" }}>
      <LoaderContainer loading={loading}>
        <PageContent.Header>
          <PageContent.Header.Title>{t("title")}</PageContent.Header.Title>
          {!fundraiseId ? (
            <div>
              <Button onClick={handleManageFundraiseSlidePanel}>{t("startNewFundraise")}</Button>
            </div>
          ) : (
            <div>
              <Button variant="tertiary" onClick={handleCancelFundraiseModal}>
                {t("cancelFundraise")}
              </Button>
              <Button className="ms-2">{t("finishFundraise")}</Button>
            </div>
          )}
        </PageContent.Header>
        {fundraiseId && <FundraiseMenu handleEditFundraise={handleManageFundraiseSlidePanel} />}

        {fundraiseId ? children : <Kickstart />}
        <SetFundraiseSlidePanel
          show={showFundraiseSlidePanel}
          onHide={handleManageFundraiseSlidePanel}
          fundraiseId={fundraiseId}
          onSaved={handleLoadPage}
        />
        <CancelFundraiseModal
          fundraiseId={fundraiseId || 0}
          onHide={handleCancelFundraiseModal}
          show={showCancelFundraiseModal}
        />
      </LoaderContainer>
    </PageContent>
  );
};
export default Fundraising;
